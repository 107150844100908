Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.showEmployeeApiEndPoint = "account_block/show_employee"
exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.sendInviteEmployeeApiEndPoint = "account_block/send_invitation"
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";
exports.updateCompanyEmployeeEndPoint = "account_block/company_update_employee";
exports.viewKycCheckListApiEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks/employee_applicant_list"

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
exports.emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+[a-zA-Z]{2,4}$/;
exports.popUpData = "Are you sure you want to send employee invitation ?";
exports.sucessMsg = "Invite has been successfully sent";
exports.homeText = "View KYC Checklist";
exports.passportNumber = "Passport No";
exports.documentText = "Documents";
exports.statusText = "Status";
exports.loadBtn = "Load more";
exports.noDataText = "No Data Found";
exports.approvedText = "Approved";
exports.approvedVerify = "Approved Verification";
exports.numberApprove = "50";
exports.nameText = "Name";
exports.kycDate = "KYC Date";
exports.pendingText = "Pending";
exports.passportPdf = "passport.pdf";
exports.rejectedverify = "Rejected Verification";
exports.pendingVerify = "Pending Verification";
exports.clarificationText = "Need Further Clarification";
exports.userInformation = "user’s  Information";
// Customizable Area End
