import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
  styled,
} from "@material-ui/core";
import EmailAccountRegistrationController, {
  CountryCodeObj,
  Props,
  configJSON,
} from "./EmailAccountRegistrationController.web";
import { checked, logo, mainImage, uncheck, arrowIconBack,mainLogo,blurImage } from "./assets";
import SelectSubscription from "./SelectSubscription.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PhoneInput from "react-phone-input-2";
import CircularProgress from '@material-ui/core/CircularProgress';
import LazyLoadImageComponentRegister from "../../../components/src/LazyLoadImageComponent"
import { LazyLoadImage } from "react-lazy-load-image-component";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  renderStep3 = () => {
  
    return (
      <Box style={{ marginBottom: "5px" }}>
        <Box className="inputContainer">
          <Typography className="lableName">Company Name*</Typography>
          <input
            className={
              this.state.error==="Company Name already exists."? "inputStyle errorStyle" : "inputStyle"
            }
            type="text"
            autoComplete="off"
            placeholder="Enter company name"
            name="companyName"
            data-test-id="companyName"
            value={this.state.formData.companyName}
            onChange={this.handleEditDescription}
            onBlur={this.handleCompanyNameBlur}
            />
          {this.state.error==="Company Name already exists." ? (
            <Typography className="errorMessage">
              {configJSON.companyNameExit}
            </Typography>
          ):null}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Email*</Typography>
          <input
            className={`inputStyle ${ this.state.errorEmail ? "errorStyle" : ""} ${this.state.emailError ? "inputStyle errorStyle": "inputStyle" }`}
            type="text"
            autoComplete="off"
            placeholder="Enter email id"
            name="email"
            data-test-id="emailInput"
            value={this.state.formData.email}
            onChange={this.handleEditDescription}
          />
         <Typography className="errorMessage">
            {this.state.emailError}
          </Typography>
          {this.state.errorEmail && (
            <Typography className="errorMessage">
              {this.state.errorEmail}
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Contact Person Name*</Typography>
          <input
          className={
            this.state.personNameError ? "inputStyle errorStyle" : "inputStyle"
          }
            type="text"
            data-test-id="personName"
            placeholder="Enter contact person name"
            name="personName"
            value={this.state.formData.personName}
            onChange={this.handleEditDescription}
            onBlur={this.handlePersonNameBlur}
            onKeyDown={this.handleKeyDown}
            autoComplete="off"
                      />
          {this.state.personNameError && (
            <Typography className="errorMessage" data-test-id="personNameError">
              {configJSON.cityErrorMessage}
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">{configJSON.phoneNumberText}</Typography>
          <Box className ="phoneNumberInputFiled">
            <PhoneInput
              data-test-id="phoneInput"
              placeholder="+91 Enter phone number"
              enableSearch={true}
              value={this.state.employeeData.dailNumber?.toString()}
              onChange={this.dailcodeData}
              inputProps={{
                required: true,
                id: "PhoneNumber",
              }}
              enableLongNumbers={true}
              disableSearchIcon={true}
              jumpCursorToEnd={true}
              autoFormat={true}
              inputStyle={{
                width: "100%",
                border: "none",
                backgroundColor: "#242424",
                color: "#A7A7A7",
                height: "inherit",
              }}
              dropdownStyle={{
                color: "#A7A7A7",
                border: "none",
                background: "none",
              }}
              containerStyle={{
                width: "98%",
                height: "46px",
                backgroundColor: "#242424",
                color: "#A7A7A7",
                border: this.state.phoneNumberStatus ? "1px solid red" : "1px solid #FFFFFF",
                paddingLeft: "2%",
              }}
              onKeyDown={this.handleKeyDown}
            />
          </Box>
          <Typography className="errorMessage">
            {this.state.phoneNumberStatus}
          </Typography>
        </Box>
        <Button
          className="customButton"
          data-test-id="ContinueBtn"
          onClick={this.createAccount}
          disabled={this.state.btnStatus}
          style={
            this.state.btnStatus
              ? {backgroundColor:"#7C7C7C" }
              : { backgroundColor: "#00FF00" }
          }
        >
           {this.state.Loader? <CircularProgress thickness={4} size={30} /> : "Continue"}
        </Button>
      </Box>
    );
  };
  renderStep4 = () => {
    return (
      <Box style={{ marginTop: "2%" }}>
        <Box className="inputContainer">
          <Typography className="lableName">
            Tax Identification Number
          </Typography>
          <input
            className="inputStyle"
            type="text"
            autoComplete="off"
            placeholder="Enter tax identification number"
            name="taxIdentificationNumber"
            value={this.state.formData.taxIdentificationNumber}
            onChange={this.handleEditDescription}
            onBlur={this.checkValidation}
            data-test-id="taxIdentificationNumber"
          />
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Website Link*</Typography>
          <input
            
            className={
              this.state.websiteLinkError ? "inputStyle errorStyle" : "inputStyle"
            }
            type="text"
            autoComplete="off"
            placeholder="Enter website link"
            name="websiteLink"
            value={this.state.formData.websiteLink}
            onChange={this.handleEditDescription}
            data-test-id="websiteLink"
          />
            {this.state.websiteLinkError &&  
         <Typography className="errorMessage"> {configJSON.websiteLinkError}</Typography>}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Address of The Company*</Typography>
          <input
            className={
              this.state.companyAddressError ? "inputStyle errorStyle" : "inputStyle"
            }
            type="text"
            autoComplete="off"
            placeholder="Enter address of the company"
            name="companyAddress"
            value={this.state.formData.companyAddress}
            onChange={this.handleEditDescription}
            data-test-id="companyAddress"
          />
          {this.state.companyAddressError && (
            <Typography className="errorMessage">
              {" "}
              {configJSON.companyAddressError}
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Street*</Typography>
          <input
            className={
              this.state.streetError ? "inputStyle errorStyle" : "inputStyle"
            }
            type="text"
            autoComplete="off"
            placeholder="Enter street"
            name="street"
            data-test_id="streetCheckTestID"
            value={this.state.formData.street}
            onChange={this.handleEditDescription}
            onBlur={this.handleStreetNameBlur}
           
          />
          {this.state.streetError && (
            <Typography className="errorMessage">
              {configJSON.cityErrorMessage}
            </Typography>
          )}
        </Box>
        <Button
          className="customButton"
          data-test-id="ContinueBtn2"
          style={
            this.state.secondBtnStatus
              ? { backgroundColor: "#7C7C7C" }
              : { backgroundColor: "#00FF00" }
          }
          disabled={this.state.secondBtnStatus}
          onClick={this.stepCount}
        >
          Continue
        </Button>
      </Box>
    );
  };
  renderStep5 = () => {
    return (
      <Box style={{ marginTop: "2%" }}>        
        <Box className="inputContainer">
          <Typography className="lableName">{configJSON.zipCodeText}</Typography>
          <input
            className="inputStyle"
            type="text"
            autoComplete="off"
            placeholder="Enter zip code"
            data-test-id="zipCodeTestId"
            name="zipCode"
            value={this.state.formData.zipCode}
            onChange={this.handleEditDescription}
          />
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Country*</Typography>      
          <input
            name="countryName"
            className="inputStyle"
            type="text"
            autoComplete="off"
            placeholder="Enter country"            
            value={this.state.formData.countryName}     
            onChange={this.handleEditDescription}    
          />
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">State*</Typography>
          <input
            type="text"
            autoComplete="off"
            placeholder="Select State"
            className="inputStyle"
            name="stateName"
            onChange={this.handleEditDescription}
            value={this.state.formData.stateNameCheck}
            id="option"
          />
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">City*</Typography>
          <input
            className="inputStyle"
            type="text"
            placeholder="Enter city"
            name="cityName"
            data-test-id="cityName"
            autoComplete="off"
            value={this.state.formData.cityNameCheck}
            onChange={this.handleEditDescription}
            
          />
          {this.state.cityError && (
            <Typography className="errorMessage">
              {configJSON.cityErrorMessage}
            </Typography>
          )}
        </Box>
        <Button
          className="customButton"
          data-test-id="ContinueBtn3"
          disabled={this.validateForm2()} 
          
          
          style={
             (this.validateForm2())
              ? { backgroundColor: "#7C7C7C" }
              : { backgroundColor: "#00FF00" }
          }
          onClick={this.stepCount}
        >
        {this.state.Loader? <CircularProgress thickness={4} size={30} /> : "Continue"}
        </Button>
      </Box>
    );
  };
  renderStep6 = () => {
    return (
      <Box style={{ marginTop: "2%" }}>
        <Box className="inputContainer">
          <Typography className="lableName">Password</Typography>
          <Input
            className="inputStyle"
            name="password"
            autoComplete="off"
            placeholder="Enter Password"
            data-test-id="passwordField"
        
            type={this.state.enablePasswordField ? "password" : "text"}
            style={
              this.state.validPass
                ? { border: "1px solid #FFFFFF" }
                : { border: "1px solid red" }
            }
            inputProps={{ disableUnderline: true, maxLength: 32  }}
            value={this.state.formData.password}
            onChange={this.handleFormData}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handlePasswordShow}
                  edge="end"
                >
                  {this.state.enablePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.validPass ? null : (
            <Typography className="errorMsg">
              Kindly meet all the provided conditions
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">Confirm New Password </Typography>
          <Input
            className="inputStyle"
            name="reTypePassword"
            autoComplete="off"
            placeholder="Enter Confirm Password"
          
            data-test-id="confirmPass"
            type={this.state.enableReTypePasswordField ? "password" : "text"}
            style={
              this.state.confirmPass
                ? { border: "1px solid red" }
                : { border: "1px solid #FFFFFF" }
            }
            value={this.state.formData.reTypePassword}
            onChange={this.handleFormData}
            inputProps={{ disableUnderline: true , maxLength: 32 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleConfirmPasswordShow}
                  edge="end"
                >
                  {this.state.enableReTypePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.confirmPass ? (
            <Typography className="errorMsg">Password must be same </Typography>
          ) : null}
        </Box>
        <Box><CheckboxLebel variant="h6"  className="passCondLable">Your password must contain</CheckboxLebel></Box>
        <Box className="controlContainer">
          <FormControlLabel
            className="checkBoxContainer"
            disabled
            control={
              <Box style={{ paddingRight: "2%" }}>
                {this.handleChecked("alphabet") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                At least one alphabet
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("number") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                At least one number
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("specialSign") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                At least one special character
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("capitalChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                At least one character in caps
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            className="checkBoxContainer"
            disabled
            control={
              <Box className="checkImg">
                {this.handleChecked("minimumChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                Minimum character length is 8 characters
              </CheckboxLebel>
            }
          />
        </Box>
        <Button
          className={this.state.isCheckPassword?"customButton":"customButtonTwo"}
          data-test-id="ContinueBtn4"
          onClick={this.createAccount}
          disabled={!this.state.isCheckPassword}
        >
          {this.state.Loader? <CircularProgress thickness={4} size={30} /> : "Continue"}
        </Button>
      </Box>
    );
  };

  render() {
    
    return (
      <SignupStyling
        style={{ overflow: "hidden", width: "100%", height: "100%" }}
      >
        <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box
              style={{
                backgroundColor: "#242424",
                borderBottom: "1px solid #505050",
                width: "100%",
                display:'flex',
                justifyContent:'flex-end',
              }}
            >
            <LazyLoadImage
               src={mainLogo}
               style={{ display: "flex",
               marginLeft: "auto",
               paddingRight: "2%",
              marginRight:'20px' }}
              placeholderSrc={logo}
               effect="blur"
               alt="logo"
               wrapperClassName="lazy-load-wrapper-logo"
                />
                                 <style>
  {
    `
    .lazy-load-wrapper-logo{
      backgroundColor: "#242424",
      width: "100%",
      borderBottom: "1px solid #505050",
    }
    `
}
</style>  

            </Box>
          </Grid>
        </Box>
        <Box>
          {this.state.step === 1 ? (
            <SelectSubscription
              stepUpdate={this.stepCount}
              navigation={this.props.navigation}
              id={""}
            />
          ) : null}
          {this.state.step >= 2 ? (
            <Grid container>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                style={{ backgroundColor: "#242424" }}
              >
                <Box className="main-container">
                  <Box style={{ width: "50%", margin: "auto" }}>
                    <img
                      src={arrowIconBack}
                      alt="Arrow Icon"
                      style={{ cursor: "pointer", marginBottom: "2px" }}
                      onClick={this.handleBackClick}
                      data-test-id="btnback"
                    />
                    <Box style={{ color: "white" }}>
                      <Typography className="createAccount">
                        Create an Account
                      </Typography>                      
                    </Box>
                    
                    {this.state.step === 2 ? this.renderStep3() : null}
                    {this.state.step === 3 ? this.renderStep4() : null}
                    {this.state.step === 4 ? this.renderStep5() : null}
                    {this.state.step === 5 ? this.renderStep6() : null} 
                                    
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>
              <LazyLoadImageComponentRegister mainImage={mainImage} blurImage={blurImage} />
              </Grid>
            </Grid>
          ) : null}
        </Box>
      </SignupStyling>
    );
  }
}
const SignupStyling = styled(Box)({
  "& .MuiGrid-grid-md-7": {
    overflowY: "auto",
  },
  "& ::-webkit-scrollbar-track": {
    background: "#024714",
   },
    "& ::-webkit-scrollbar-thumb": {
    background: "#024714",
   },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiIconButton-edgeEnd": {
    marginRight: "0px",
  },
  "& .main-img": {
    width: "100%",
    height: "calc(100vh - 71px)",
    objectFit: "cover",
    objectPosition: "bottom",
    "@media(max-width: 960px)": {
      display: "none",
    },
  },
  "& input::-webkit-outer-spin-button input::-webkit-inner-spin-button": {
    "-webkit-appearance": " none",
    margin: "0",
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& .errorMsg": {
    color: "red",
    fontSize: "12px",
  },
  "& .react-tel-input .flag-dropdown": {
    background: "none",
    border: "none",
  },
  "& .flag-dropdown::hover": {
    background: "none",
    border: "none",
  },
  "& .react-tel-input": {
    width: "30%",
    height: "46px",
  },
  "& .react-tel-input .form-control": {
    width: "74%",
    paddingLeft: "28px",
    backgroundColor: "rgb(37 37 37) !important",
  },
  "& .react-tel-input .selected-flag": {
    width: "0px",
    padding: "0px",
    borderRadius: "0px",
  },

  "& .react-tel-input .country-list": {
    zIndex: 37,
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    width: "377px",
    borderRadius: "7px",
    backgroundColor: "#2f2f2f !important",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 71px)",
  },
  "& .createAccount": {
    fontFamily: "Lato",
    letterSpacing: "-0.005em",
    fontSize: "30px",
    fontWeight: 700,
    color: "#D3D3D3",
    lineHeight: "40px",
    textAlign: "left",
  },
  "& .subheading": {
    fontSize: "1rem",
    color: "#D3D3D3",
  },
  "& .lableName": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: "Lato",
    lineHeight: "22px",
    textAlign: "left",
    paddingBottom: "4px",
  },
  "&. phoneNumberInputFiled":{
    display: "flex"
  },
  "& .inputStyle": {
    width: "100%",
    height: "47.6px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "12px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
  },
  "& .phoneInputStyle": {
    width: "100%",
    height: "48px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "16px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    outline:"none"
  },
  "& .errorStyle": {
    border: "1px solid red",
  },
  "& .errorMessage": {
    color: "red",
  },

  "& .emailError": {
    marginTop: "10px",
    color: "red",
  },
  "& .inputStyle:focus": {
    outline: "none",
  },
  "& .phoneinput": {
    borderLeft: "none",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "3%",
  },
  "& .customButton": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "2%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
  },
  "& .customButtonTwo":{
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "2%",
    backgroundColor: "#7C7C7C",
    fontWeight: 600,
  },
  "& .phoneCodeStyle": {
    position: "absolute",
    color: "white",
    marginTop: "1%",
  },
  "& .checkImg": {
    paddingRight: "2%",
  },
  "& .MuiSvgIcon-root": { color: "white" },
  "& .checkBoxContainer": {
    marginTop: "4px",
  },
  "& .controlContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "2%",
    marginLeft: "3%",
  },
  "& .checkBoxlabel": {
    color: "#D3D3D3",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
  },
  "& .loading-container": {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  "& .spinner": {
    height: "80px",
    animation: "spin 1s ease-in-out infinite",
    borderRadius: "50%",
    borderTopColor: "#000",
    border: "6px solid rgba(0, 0, 0, 0.1)",
    width: "80px",
  },
  "& .loading-text": {
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "10px",
  },
  "& .passCondLable":{
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '16px',
  },
  scrollbarColor: "#024714 #f1f1f1",
});

const CheckboxLebel = styled(Typography)({
  fontSize: "12px",
  lineHeight: "unset",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "small",
  },
});
