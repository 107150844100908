import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import React from "react";
const navigation = require("react-navigation");
import {getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import debounce from 'debounce';
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface ZipCodeAttributes {
  id: number;
  zip_code: string;
  country_code: string;
  postal_area: string;
  city: string;
  state: string;
  country_full_name: string;
  nationality: string;
}

interface ZipCodeData {
  id: string;
  type: string;
  attributes: ZipCodeAttributes;
}

interface ZipCodeResponse {
  data: ZipCodeData[];
}

interface ApplicantStatus {
  review_result: {
      reviewAnswer: string; 
  };
  review_status: string;
}

interface ApplicantAttributes {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  gender: string | null;
  dob: string | null;
  placeOfBirth: string | null;
  countryOfBirth: string | null;
  stateOfBirth: string | null;
  country: string | null;
  nationality: string | null;
  addresses: string | null;
  applicant_id: string;
  passport_number: string | null;
  state: string | null;
  city: string | null;
  zip_code: string | null;
  addresses_2: string | null;
  passport: string | null;
  profile_picture: string | null;
  status: ApplicantStatus;
  documents: any | null; 
}

interface ApplicantUser {
  id: string;
  type: string; 
  attributes: ApplicantAttributes;
}

interface ApplicantMeta {
  current_page: number | undefined;
  per_page: number | undefined;
  total_count?: number | undefined;
}

interface ApplicantResponse {
  data: ApplicantUser[];
  meta: ApplicantMeta;
}

export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
};

interface KYCReviewResponse {
  message: string;
  applicant_user: {
    id: number;
    account_id: number;
    firstName: string;
    lastName: string;
    middleName: string | null;
    gender: string | null;
    dob: string;
    placeOfBirth: string;
    countryOfBirth: string | null;
    stateOfBirth: string | null;
    country: string; 
    nationality: string;
    addresses: string;
    applicant_id: string;
    reviewStatus: string; 
    reviewAnswer: string | null;
    passport_number: string |null;
    state: string;
    city: string;
    zip_code: string;
    addresses_2: string;
    created_at: string; 
    updated_at: string;
    company_candidate_id: string | null;
  };
  errors?: string[];

}


interface CityResponse {
  name: string,
  display_name: string
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}
interface User {
  user_list:[];
  total_count: number;
  id: number;
  role_id: string;
  company_name: string;
  email: string;
  full_phone_number: string;
  contact_person_name: string;
  tax_identification_number: string;
  website_link: string; 
  address: string;
  street: string;
  city: string;
  zip_code: string;
  state: string;
  country: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_type: string | null;
  activated: boolean;
  company_unique_id: string;
  address_line_two: string; 
  total_credits: number | null; 
  avaliable_credits: number | null; 
  first_name: string | null; 
  last_name: string | null;
  middle_name: string | null;
  nationality: string | null;
  date_of_birth: string | null;
  passport_number: string | null;
  status: string;
}
export interface Dropdown {
  label: string;
  value: string;
}

interface AppState {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  nationality: string;
  country: string;
  addressOne: string;
  addressTwo: string;
  state: string;
  city: string;
  zipCode: string;
  passportNumber: string;
  countryThreeDigit:string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}

export interface FormError {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dateOfBirth?: string;
  nationality?: string;
  country?: string;
  addressOne?: string;
  addressTwo?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  passportNumber?: string;
  draggedFile?: string;
  passport?: string;
  pan?: string;
  marksheet?: string;
  adhar?: string;
}
export interface SearchData {
  id: number;
  userPic: string;
  name: string;
  dateBirth: string;
  nationality: string;
  country: string;
  address: string;
  passportNo: number;
  documentName: Array<string>;
  documents: Array<string>;
  status: string;
}


interface IEmployee {
  id: number|string;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  full_name: string | null;
  role_id: number;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  credit: number | null;
  company_name: string;
  employee_name: string;
  company_unique_id: string;
  middle_name: string | null;
  nationality: string | null;
  address: string | null;
  address_line_two: string | null;
  state: string | null;
  city: string | null;
  zip_code: number | null;
  passport_number: string | null;
  country: string | null;
  designation: string | null;
  website_link: string | null;
  is_subscribed: boolean;
  invited_by_company: boolean;
}

interface IEmployeeResponse {
  employees: IEmployee[];
  total_count: number;
}


// Customizable Area End

  // Customizable Area Start
export interface S {
  loading: boolean;
  userId: string;
  dataFilter: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  userState: AppState;
  imageModal: boolean;
  imageModalSecond:boolean;
  docType: string;
  inputId: string;
  fileContent: string;
  files: File[];
  selectedImage: null| File;
  imgSizeExceed: boolean;
  invalidFileType: boolean;
  invalidFileType2: boolean;
  draggingIndex: number | null;
  documentTypeModal: boolean;
  documentType: string;
  successModal: boolean;
  draggedFile: File | null;
  passport: File | null;
  pan: File | null;
  marksheet: File | null;
  adhar: File | null;
  userFormError: FormError;
  activeSubmitBtn : boolean;
  anchorEl: HTMLButtonElement | null;
  rejectedText:boolean;
  scanModal: boolean;
  tableData: SearchData[];
  peopleManagementData:IEmployee[];
  sideBarClose:boolean;
  role: string;  
  tablesData:User[];
  isDialogOpen:boolean;
  successDialogOpen:boolean;
  deleteId:string|number;
  token:string;
  addressError: boolean,
  adressTwoError: boolean,
  pageCount: number,
  perPage: number;
  totalDataCount: number;
  searchStatusData: Array<{[key:string]: string}>;
  searchValue: string;
  tableHeaderData: Array<{[key:string]: string}>;
  selectedDate: Date | null;
  open: boolean;
  selectedId: string|number;
  empPageCount:number;
  totalEmployeeDataCount:number;
  empPerPage:number;
  searchEmpValue: string;
  checkSDK : boolean;
  reviewStatus: string | null;
  userData:ApplicantUser[];
  userMeta:ApplicantMeta;
  userDataLoading:boolean;
  downloadPdfLoader1:boolean;
  searchKey:string;
  Loader:boolean;
  customUserListLength:number;
  customperPage:number;
  customCurrentPage:number;
  ocrFirstName:string;
  ocrMiddleName:string;
  ocrSurname:string;
  ocrPassportNo:string;
  ocrNationality:string;
  ocrDob:string;
  companyId:string;
  noDataFound:boolean;
  noDataFoundPeople:boolean,
  status:string;

}
  // Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fileInputRef: React.RefObject<HTMLInputElement>;
  getUserListApiCallId: string = "";
  getCityAPICallId : string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getUserDataApiCallId:string="";
  getEmployeeListApiCallId :string="";
  getCompanyEmployeeListApiCallId : string="";
  deleteEmployeeApiCallId :string = "";
  uploadDocumentApiCallId: string = "";
  getUserListDataApiCallId : string = "";
  initialState: AppState = {
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    nationality: '',
    country: '',
    addressOne: '',
    addressTwo: '',
    state: '',
    city: '',
    zipCode: '',
    passportNumber: '',
    countryThreeDigit:''
  };
  initialFormState: FormError = {
    lastName: "",
    middleName: "",
    dateOfBirth: "",
    nationality: "",
    country: "",
    addressOne: "",
    addressTwo: "",
    state: "",
    city: "",
    zipCode: "",
    passportNumber: "",
    draggedFile: "",
    passport: "",
    pan: "",
    marksheet: "",
    adhar: "",
  };
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      ocrFirstName:"",
  ocrMiddleName:"",
  ocrSurname:"",
  ocrPassportNo:"",
  ocrNationality:"",
  ocrDob:"",
      searchKey:"",
      status:"",
      customUserListLength:0,
      customperPage:10,
      customCurrentPage:1,
      userDataLoading:false,
      noDataFound:false,
      noDataFoundPeople:false,
      dataFilter: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      userState: this.initialState,
      imageModal: false,
      imageModalSecond:false,
      docType: "",
      inputId: "",
      fileContent: '',
      files: [],
      selectedImage: null,
      imgSizeExceed: false,
      invalidFileType: false,
      invalidFileType2: false,
      draggingIndex: null,
      documentTypeModal: false,
      documentType: "",
      successModal: false,
      draggedFile: null,
      passport: null,
      pan: null,
      marksheet: null,
      adhar: null,
      userFormError: this.initialFormState,
      activeSubmitBtn: false,
      anchorEl: null,
      rejectedText:true,
      tableData: [
        {
          id: 1,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "David Smith",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP ,456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 2,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Aisyah Clara",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "pending",
        },
        {
          id: 3,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Anastasia De",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 4,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Shirely Wong",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "rejected",
        },
        {
          id: 5,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Alexandre",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 6,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Jakub Hoxha",
          dateBirth: "22/04/2002",
          nationality: "American",
          country: "United States",
          address: "A8, MIG colony, Ambedkar Nagar, Indore, MP, 456789",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "need clarification",
        },
      ],
      peopleManagementData:[],
      scanModal: true,
      sideBarClose :false,
      role: "",
      tableHeaderData: [
        {
          name:  "Name",
          className: "nameTableCell"
        },
        {
          name:  "Date of Birth",
          className: "dobTableCell"
        },
        {
          name:  "Nationality",
          className: "nationalityTableCell"
        },
        {
          name:  "Country",
          className: "countryTableCell"
        },
        {
          name:  "Address",
          className: "addressTableCell"
        },
        {
          name:  "Passport No",
          className: "passportTableCell"
        },
        {
          name:  "Documents",
          className: "documentTableCell"
        },
        {
          name:  "Status",
          className: "statusTableCell"
        }
      ],
      tablesData:[],
      isDialogOpen:false,
      successDialogOpen:false,
      deleteId:"",
      token:"",
      addressError: false,
      adressTwoError: false,
      pageCount: 1,
      perPage: 10,
      totalDataCount: 0,
      searchStatusData: [
        {
          status: "approved",
          className: "searchApprovedStatus"
        },
        {
          status: "pending",
          className: "searchPendingStatus"
        },
        {
          status: "rejected",
          className: "searchRejectedStatus"
        },
        
        {
          status: "need clarification",
          className: "searchClarificationStatus"
        }
      ],
      searchValue: "",
      searchEmpValue:"",
      downloadPdfLoader1:false,
      selectedDate: null,
      open: false,
      selectedId: "",
      empPageCount:1,
      totalEmployeeDataCount:0,
      empPerPage:10,
      checkSDK:false,
      reviewStatus: "",
      userData:[],
      userMeta: {
      current_page: 1,
      per_page: 10,
      total_count: 0,
      },
      companyId:"",
      Loader:false,
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      

      if (apiRequestCallId && responseJson) {          
        if (responseJson.status === 500) {
          toast.error("Something went wrong!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ loading: false,Loader: false  });  
        }   
        else if (responseJson.errors) {    
          responseJson?.errors?.map((error:any)=>{
            toast.error(error?.token,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )        
          })  
          this.setState({ loading: false,userDataLoading:false,Loader: false  });
        } 
        else if (apiRequestCallId == this.getUserListApiCallId) {
          let filterData = responseJson.data.map((item: ResponseJson) => {
            return {
              userId: item.id,
              firstName: item.attributes.first_name,
              lastName: item.attributes.last_name,
              phoneNumber: item.attributes.phone_number,
              email: item.attributes.email,
              organization: item.attributes.organization,
              teamName: item.attributes.team_name,
              userType: item.attributes.i_am,
              rating: item.attributes.stars_rating.toString(),
              gender: item.attributes.gender,
              address: item.attributes.address,
              country: item.attributes.country,
              state: item.attributes.state,
              city: item.attributes.city,
              file: item.attributes.file.file_name,
            };
          });
          this.setState({ filterData,loading:false }, () => {
            this.onChangeHandler(0);
          });
        } else if (apiRequestCallId == this.addUserApiCallId) {
          toast.success("User added successfully!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ openDialogName: "" });
          this.getUserList();
        } else if (apiRequestCallId == this.editUserApiCallId) {
          toast.success("User edited successfully!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
          this.setState({ openDialogName: "", isChange: false });
          this.getUserList();
        }
      }

      let apiIdList = {
        [this.uploadDocumentApiCallId]: this.uploadDocumentdataFunction,      
        [this.getUserListDataApiCallId]: this.getuserDataFunction,          
        [this.getUserDataApiCallId]: this.userInformationFunction,           
      }   
      let functionName = apiIdList[apiRequestCallId]
      if (functionName)
        functionName(responseJson)
      this.apiResponseData(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllUSerDataList();
    this.getCompanyEmployeeData("");
    const role = await getStorageData("role");
    const tokenId = await getStorageData("token");
    const companyId = await getStorageData("UserID");
    this.setState({
      role,token:tokenId,companyId:companyId
    });
  }
  
  
  uploadDocumentdataFunction=(responseJson:any)=>{
    this.updateDocumentApiResponse(responseJson)
   }
   getuserDataFunction=(responseJson:any)=>{
    this.getUserListDataAPIResponse(responseJson)
   }
   userInformationFunction=(responseJson:any)=>{
    this.setState({
      loading: false
    });
    this.userDataAPIResponse(responseJson) 
   }

  apiResponseData = async (apiRequestCallId : string,responseJson : ZipCodeResponse & IEmployeeResponse) =>{
    if (apiRequestCallId === this.getEmployeeListApiCallId) {
      this.companyEmployeeDataAPIResponse(responseJson) 
   }
   if(apiRequestCallId === this.deleteEmployeeApiCallId){
     this.deleteEmployAPIResponse(responseJson)
   }
   if(apiRequestCallId === this.getCityAPICallId){
       this.handleCityCheckResponse(responseJson)
   };
  }

  userDataAPIResponse = async(responseJson:User) => {
      if(responseJson.user_list.length > 0){  
       this.setState({ 
        tablesData:responseJson.user_list  ,
        totalDataCount: responseJson.total_count,
        loading:false});
      }else{
        this.setState({loading:false})
      }
  }

  setDownloadPdfLoader1=(val:boolean)=>{

    this.setState({
      downloadPdfLoader1:val
    })
   }
 

   companyEmployeeDataAPIResponse = (responseJson:IEmployeeResponse) => {
    const data = responseJson.employees ? responseJson.employees : []
      if(data.length === 0)
        {
          this.setState({
            noDataFoundPeople:true
          })
        }
    
    this.setState({ peopleManagementData: data ,totalEmployeeDataCount: responseJson.total_count,loading:false});
  }

  handleLoaderPm =()=>{
    return this.state.peopleManagementData.length > 0 
   }

   getCompanyEmployeeData = async(value:unknown) => {
    this.setState({userDataLoading:true});
    const token = await getStorageData("token");
    const companyId = await getStorageData("UserID");
    const header = {
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmployeeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),  
      `${configJSON.getCompanyEmployeeListEndPoint}?&company_id=${Number(companyId)}&per_page=${this.state.empPerPage}&page=${this.state.empPageCount}&search=${String(value)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteEmployee = (id: number) => {
    const header = {
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEmployeeApiEndPoint}?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteEmployeeApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleConfirm = () => {
    const toNum=Number(this.state.deleteId)
    this.deleteEmployee(toNum)
  };

  deleteEmployAPIResponse = (responseJson :any) =>{
    if(responseJson.errors){
      toast.error("Employee Does Not Exit!",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    }else{
      this.setState({ isDialogOpen: false ,anchorEl: null, successDialogOpen:true});
      this.getCompanyEmployeeData("");
    }
  }

  handleDialogBox = (id:string|number) =>{
    this.setState({ isDialogOpen: true,deleteId:id });
  }

  handleCancel = () => {
    this.setState({ isDialogOpen: false ,anchorEl: null,successDialogOpen:false});
  };

  getUserDataFuntion = async(value : unknown) => {
    this.setState({
      loading: true
    });
    const role_type = await getStorageData("role")
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDataEndPoint}?role=${role_type}&search=${String(value)}&per_page=${this.state.userMeta.per_page}&page=${this.state.pageCount}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  onChangeHandler = (page: number) => {
  let { rowsPerPage, filterData } = this.state;
  let dataFilter = filterData;
  const dataLength = dataFilter.length;
  let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
  page = totalPage === page ? page - 1 : page;
  dataFilter = dataFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  this.setState({ dataFilter, dataLength, page, loading: false });
  };

  getUserList = () => {
  this.setState({ loading: true });

  const header = {
  "Content-Type": configJSON.getUserListApiContentType,
  token: localStorage.getItem("token"),
  };

  const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUserListApiCallId = requestMessage.messageId;
  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
  );

  requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.getUserListApiEndPoint
  );

  requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.getUserListApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  };



  updateOcrData = (firstName:string, middleName:string, surname:string, passportNo:string, nationality:string, dob:string) => {
  

    this.setState((prevState) => ({
      userState: {
        ...prevState.userState,
          firstName: firstName,
          middleName:middleName,
          lastName:surname,
          passportNumber:passportNo,
          nationality:nationality,
          dateOfBirth:dob
      },
    }))
  };
 



  handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if(name === "cardNumber"){
       this.setState({tablesData:[], searchValue: value as string},()=>{
         this.getUserDataFuntion(value);
       })
    }
    else if (name === "countryAlpha") {
      const countryFullName = configJSON.countries[value as string]; 
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          country: countryFullName, 
          countryThreeDigit: value as string, 
        },
      }));
    }
    else if (name === "firstName") {
      this.handleFirstName(value);
    }
    else if (name === "lastName") {
      this.handleLastName(value);
    }
    else if (name === "city") {
      this.handleCityName(value);
    }
    else if (name === "passportNumber") {
      this.handlePassportName(value);
    }
    else if (name === "nationality") {
      this.handleNationalityName(value);
    }
    else if (name === "state") {
      this.handleStateName(value);
    }
    else if (name === "middleName") {
      this.handleMiddleName(value);
    }
    else if (name === "addressOne") {
      this.handleAddressFirst(event);
    }
    else if (name === "addressTwo") {
      this.handleAddressSecond(event);
    }
    else {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          [name as string]: value,
        },
      }), ()=> {
        if(this.state.userState.zipCode){
          if(this.state.userState.zipCode.length === 6){
            this.getCityDetail(this.state.userState.zipCode)
          } 
        } 
      });
    }
  };

  handleChangeEmployee =(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if(name === "cardNumber"){
       this.setState({tablesData:[], searchEmpValue: value as string},()=>{
         this.getCompanyEmployeeData(value);
       })
    }
  }

  handleLastName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["lastName" as string]: value,
        },
      }));
    }
  };
  handleCountryName = (value: unknown) => {
    this.setState((prevState) => ({
      userState: {
        ...prevState.userState, ["country" as string]: value,
      },
    }));
  };
  handleStateName = (value: unknown) => {
    this.setState((prevState) => ({
      userState: {
        ...prevState.userState, ["state" as string]: value,
      },
    }));
  };
  handleNationalityName = (value: unknown) => {
    this.setState((prevState) => ({
      userState: {
        ...prevState.userState, ["nationality" as string]: value,
      },
    }));
  };
  handleCityName = (value: unknown) => {
    this.setState((prevState) => ({
      userState: {
        ...prevState.userState, ["city" as string]: value,
      },
    }));
  };
  handlePassportName = (value: unknown) => {
    this.setState((prevState) => ({
      userState: {
        ...prevState.userState, ["passportNumber" as string]: value,
      },
    }));
  };

  handleFirstName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["firstName" as string]: value,
        },
      }));
    }
  };

  handleMiddleName = (value: unknown) => {
    const regex = configJSON.nameRegex;
    if (regex.test(value as string)) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState, ["middleName" as string]: value,
        },
      }));
    }
  };

  handleAddressFirst = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const isDeleting = value.length < this.state.userState.addressOne.length;

    if (isDeleting || value.length < 52) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          ["addressOne"]: value,
        },
        addressError: false,
      }));
    } else {
      this.setState({ addressError: true });
    }
  };

  handleAddressSecond = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as string;
    const isDeleting = value.length < this.state.userState.addressTwo.length;

    if (isDeleting || value.length < 52) {
      this.setState((prevState) => ({
        userState: {
          ...prevState.userState,
          ["addressTwo"]: value,
        },
        adressTwoError: false,
      }));
    } else {
      this.setState({ adressTwoError: true });
    }
  };

  handleVerify = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { userState } = this.state;
    const formError = this.validate(userState);
    this.setState({ userFormError: formError });
  };

  validate = (values: AppState): FormError => {
    const errors: FormError = {};

    if (!values.firstName) {
      errors.firstName = "Please enter First Name";
    }

    if (!values.lastName) {
      errors.lastName = "Please enter last Name";
    }

    if (!values.dateOfBirth) {
      errors.dateOfBirth = "Please enter date of birth";
    }

    if (!values.nationality) {
      errors.nationality = "Please enter Nationality";
    }
    if (!values.country) {
      errors.country = "Please enter Country Name";
    }
    if (!values.addressOne) {
      errors.addressOne = "Please enter address";
    } 
    if (!values.state) {
      errors.state = "Please enter State name";
    }
    if (!values.city) {
      errors.city = "Please enter city name";
    }
    if (!values.zipCode) {
      errors.zipCode = "Please enter zip code";
    }
    if (!values.passportNumber) {
      errors.passportNumber = "Please enter passport number";
    }
    if (!this.state.passport) {
      errors.passport = "Please upload Paasport";
    }
    return errors;
  };

  handleModalOpen = (docType: string, inputId: string) => {
    this.setState({ imageModal: true, docType: docType, inputId: inputId }, () => {
    })
  };

  handleModalClose = () => {
    this.setState({ imageModal: false, docType: "" })
  };

  handleDocumentClose = () => {
    this.setState({ documentTypeModal: false })
  };

  handleSuccessModalClose = () => {
    this.setState({ successModal: false, documentTypeModal: false })

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CustomFormUserList");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleAvatarFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileType = file.type;
      const allowedTypes = configJSON.uploadFileTypes;
      const maxSizeMB = 10;

      if (!allowedTypes.includes(fileType)) {
        this.setState({
            imgSizeExceed: false,       
            invalidFileType: true ,          
        });
        return;
    }

    if (file.size > maxSizeMB * 1024 * 1024) {
      this.setState({
          imgSizeExceed: true,        
          invalidFileType: false,     
      });
      return;
  }

  this.setState({ selectedImage: files[0], imgSizeExceed: false,  invalidFileType: false });
    }
  };

  handleAddressBlur = () => {
    this.setState({
      addressError: false
    })
  };

  handleAddressTwoBlur = () => {
    this.setState({
      adressTwoError: false
    })
  };


  handleButtonClick = () => {
    const fileInput = document.getElementById(this.state.inputId);
    if (fileInput) {
      fileInput.click();
    }
  };

  handlePassportFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {      
      const file = files[0];
      const fileType = file.type;
      const allowedTypes = configJSON.uploadFileTypes;  
      
      if (!allowedTypes.includes(fileType)) {
        this.setState({               
            invalidFileType2: true ,       
            imageModal: false,      
            passport:null
        });
        return;
    }
      this.setState({ passport: files[0],  invalidFileType2: false, imageModal: false,   });
    }
  };

  handleMarksheetFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.setState({
        marksheet: files[0]
      });
      this.handleFileUpload()
    }
  };

  handlePanFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.setState({
        pan: files[0]
      });
      this.handleFileUpload()
    }
  };

  handleAdharFiles = (selectedFiles: FileList) => {
    this.setState({
      adhar: selectedFiles[0]
    });
    this.handleFileUpload()
  };

  handleAdharFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      this.handleAdharFiles(files);
    }
  };

  onChooseFile = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.setState({ imgSizeExceed: false });
      this.setState({ invalidFileType: false });
    }
  };

  handleUploadDocument = () => {
    this.setState({ successModal: true });
  };
  handleUploadDocument2 = () => {
    this.setState({ documentTypeModal: false });
  };

  handleDocumentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ documentType: event.target.value })
  }

  handleFileUpload() {
    this.setState({ documentTypeModal: true, imageModal: false, imageModalSecond: false });

  }

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.setState({ draggedFile: file });
  }

  handleFileNameLength = (fileName: string | undefined) => {
    if (fileName === undefined) {
      return null
    } else if (fileName.length > 20) {
      const newFileName = fileName.slice(0, 20)
      const fileExtension = fileName.split('.').pop();
      return `${newFileName}.${fileExtension}`
    } else {
      return fileName
    }
  };
  
  handleActiveButton = () => {
    const { userState } = this.state;
    if (!userState.firstName ||   
      !userState.lastName || 
      !userState.dateOfBirth || 
      !userState.addressOne || 
      !userState.nationality || 
      !userState.country || 
      !userState.state || 
      !userState.city || 
      !userState.zipCode ||
      !userState.passportNumber ||
      !this.state.passport||
      this.state.imgSizeExceed||
      this.state.invalidFileType ||
      this.state.invalidFileType2 ) {
      return "verifyBtn"
    }else{
      return "verifyBtnActive"
    }
  };

  handleLoader =()=>{ 
   return !this.state.loading && this.state.userData.length > 0 
  }


  shouldShowDownloadIcon = (reviewAnswer: string | undefined): boolean => {
    return reviewAnswer === 'GREEN' || reviewAnswer === 'RED' || reviewAnswer === 'PENDING';
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickID = (event: React.MouseEvent<HTMLButtonElement>, id:string|number) => {
    this.setState({ anchorEl: event.currentTarget,selectedId: id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNeedClarification = () => {
    this.setState({ rejectedText: !this.state.rejectedText, anchorEl: null });
  };

  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }

  kycNavigation = () => {
    const msgTest: Message = new Message(
      getName(MessageEnum.NavigationToKycScreen)
    );
    msgTest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgTest);
  }

  customFromGoBack=()=>{
    this.props.navigation.goBack("");
  }

  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  
  navigateInviteScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountGroups");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToEditEmployee = async(kycEmail:string,id: string|number,screenName?:string)=> {
    await setStorageData("eeid",id)
    await setStorageData("kycEmail",kycEmail)
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
    
  handleCityCheckResponse = (responseJson: ZipCodeResponse) => {  
    const details = responseJson.data[0].attributes;
    this.setState({
      userState: {
        ...this.state.userState,
        city: details.city,
        state: details.state,      
        nationality: details.nationality,
      }
    });
  }
  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getCityDetail = async (zipCode:string) => {
    if(zipCode) {
      this.getCityAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.formAPiMethod,
        endPoint: `account_block/zip_code_search?query=${zipCode}`,
      });
    }
  };

  handlePageChange = (event: object, customCurrentPage: number) => {
    this.setState(
      {
       customCurrentPage
      }, () => {
      this.getAllUSerDataList();
    })
  };

 

  handleEmployeePageChange = (event: object, empPageCount: number) => {
    this.setState({
      empPageCount
    }, () => {
      this.getCompanyEmployeeData("");
    })
  };

  clearData = () => {
    this.setState({ searchValue: "" },()=>{
      this.getUserDataFuntion("");
    })
  };

  clearDataEmployee = () => {
    this.setState({ searchEmpValue: "" },()=>{
      this.getCompanyEmployeeData("");
    })
  };

  handleDateChange = (date: Date | null) => {
    const formattedDate = date ? date.toLocaleDateString('en-GB'): '';
    this.setState((prevState: S) => ({
      selectedDate: date,
      open: false,
      userState: {
        ...prevState.userState,
        dateOfBirth: formattedDate,
      },
    }));
  };

  handleOnclick = () => {
    this.setState({
      open: !this.state.open
    });
  };




  CheckSDK = () => {
    this.setState({
      checkSDK: !this.state.checkSDK
    })}
    
  handleRemoveData = () => {
    this.setState({
      userState: this.initialState,
      passport: null,
      selectedDate: null
    });
  };
  
  updateDocumentApiResponse = (responseJson: KYCReviewResponse) => {    
    this.setState({ Loader: false })        
    if(responseJson.message === "Invalid token"){
      toast.error("Invalid token",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ Loader: false })     
    }
    if(responseJson.message	==="Company has no available credits"){
      toast.error("No KYC credits available please purchase",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ Loader: false })     
    }
    if(responseJson.message    ==="Employee's associated company has no available credits"){
      toast.error("No KYC credits available please purchase",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ Loader: false })
    }
    if(responseJson.applicant_user.reviewStatus==="Failed to create applicant"){
      toast.error(responseJson.applicant_user.reviewStatus,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ Loader: false })     
    }  
    if(responseJson.applicant_user.applicant_id){
      this.handleUploadDocument()
    }  
    this.setState({
      reviewStatus: responseJson.applicant_user.reviewStatus
    });   
    this.setState({ Loader: false })     
  };
  verifyUserDoc = async () => {
    this.setState({ Loader: true }) 
    const token = await getStorageData("token");
    const formData = new FormData();

    formData.append("data[firstName]", this.state.userState.firstName);  
    formData.append("data[lastName]", this.state.userState.lastName);
    formData.append("data[middleName]", this.state.userState.middleName);
    formData.append("data[addresses_2]", this.state.userState.addressTwo);
    formData.append("data[addresses]", this.state.userState.addressOne); 
    if (this.state.selectedDate) {
      const formattedDate = this.state.selectedDate.toISOString().split('T')[0];  
      formData.append("data[dob]", formattedDate);
    }
    formData.append("data[nationality]", this.state.userState.nationality);
    formData.append("data[city]", this.state.userState.city);
    formData.append("data[state]", this.state.userState.state);
    formData.append("data[passport_number]", this.state.userState.passportNumber);
    formData.append("data[zip_code]", this.state.userState.zipCode);
    if (this.state.selectedImage) {
      formData.append("data[profile_picture]", this.state.selectedImage);
    }    
  
    if (this.state.passport) {
      formData.append("data[passport]", this.state.passport);
    }
    formData.append("data[country]", this.state.userState.countryThreeDigit);
    formData.append("data[idDocType]","PASSPORT");

    const header = {
      'token': token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadDocumentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadDocumentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.formAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  };
  getUserListDataAPIResponse = (responseJson: ApplicantResponse) => {


    const totalItems = Number(responseJson.meta?.total_count) || 0;

    if(totalItems === 0)
      {
        this.setState({noDataFound:true})
      }

   this.setState({
    userData: responseJson.data, 
    customUserListLength:totalItems,
    userMeta: {
      current_page: responseJson.meta.current_page,
      per_page: responseJson.meta.per_page,
      total_count: responseJson.meta.total_count,
    },
    userDataLoading:false
  });
  };
  getAllUSerDataList =async () => {
    this.setState({userDataLoading:true});
    const role_type = await getStorageData("role");
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserListDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllUserDataListEndPoint}?role=${role_type}&per_page=${this.state.customperPage}&page=${this.state.customCurrentPage}&search=${this.state.searchKey}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  private debouncedGetUserList = debounce(this.getAllUSerDataList.bind(this), 1000);


  handleChangeSearchFun=(event: React.ChangeEvent<{ value: unknown }>)=>{

    const {value } = event.target;

    this.setState((prevState) => ({
      ...prevState,
      searchKey:  (value as string), 
    }
  )
  ,
    () => {
   
      this.debouncedGetUserList();
    }
  );

  }




  // Customizable Area End
}
