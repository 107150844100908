import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import {
  createTheme,
} from '@mui/material/styles';
import {  ArrowBack } from '@mui/icons-material';
import { InputAdornment } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Headers from '../../../components/src/Headers.web';
import SuccessDialog from '../../../components/src/SuccessDailogox.web';
import CircularProgress from '@material-ui/core/CircularProgress';
import InviteEmployeeController, {
  Props,configJSON
} from './InviteEmployeeController.web';
import PhoneInput from "react-phone-input-2";
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

export default class InviteEmployee extends InviteEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { designation, phoneNumber, employeeEmail, employeeName } = this.state;
    return (
        <Headers sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData="PeopleManagement"
        >
          <StyledContainer maxWidth="sm" style={{height: "100%"}}>
            <BoxContainer style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box",padding:"0 20px"}}>
              <Grid className='headerBox' container spacing={2} alignItems="center">
                <Grid style={{ padding: "0px" }} item>
                  <IconButton edge="start" >
                    <ArrowBack className='backimg' data-testId="backButtonTest" onClick={() => this.goToScreen("PeopleManagement")} style={{}}/>
                  </IconButton>
                </Grid>
                <Grid style={{ padding: "0px 16px" }} item>
                  <Typography className='headerTxt' variant="h5">Invite Employee</Typography>
                </Grid>
              </Grid>
              <form noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <label className="labelText">Employee Name <span style={{ color: 'red' }}>*</span></label>
                  <CustomTextField
                    fullWidth
                    margin="normal"
                    name="employeeName"
                    placeholder="Employee Name"
                    value={employeeName}
                    onChange={this.handleChange}
                    variant="outlined"
                    className="textName"
                    error={Boolean(this.state.empNameError)}
                    helperText={this.state.empNameError}
                    data-testId="empNameText"
                  />
                </Grid>
                <Grid item xs={12} sm={6}> 
                <label className="labelText">Enter Designation <span style={{ color: 'red' }}>*</span></label>
                  <CustomTextField
                    fullWidth
                    margin="normal"
                    name="designation"
                    placeholder="Enter Designation"
                    value={designation}
                    onChange={this.handleChange}
                    variant="outlined"
                    className="textName"
                    error={Boolean(this.state.deginationError)}
                    helperText={this.state.deginationError}
                    data-testId="designationTest"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <label className="labelText">Phone Number <span style={{ color: 'red' }}>*</span></label>
                  <Box style={{ display: "flex" , paddingTop:"22px"}}>
                    <PhoneInput
                      data-test-id="phoneInput"
                      placeholder="+91 Enter phone number"
                      enableSearch={true}
                      value={phoneNumber}
                      onChange={this.dailcodeData}
                      inputProps={{
                        required: true,
                        id: "PhoneNumber",
                      }}
                      enableLongNumbers={true}
                      disableSearchIcon={true}
                      jumpCursorToEnd={true}
                      autoFormat={true}
                      inputStyle={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#242424",
                        color: "#A7A7A7",
                        height: "inherit",
                      }}
                      dropdownStyle={{
                        color: "#A7A7A7",
                        border: "none",
                        background: "none",
                      }}
                      containerStyle={{
                        width: "100%",
                        height: "46px",
                        backgroundColor: "#242424",
                        color: "#A7A7A7",
                        border: this.state.phoneNumberStatus
                          ? "1px solid red"
                          : "1px solid #FFFFFF",
                        paddingLeft: "2%",
                      }}
                    />
                  </Box>
                  <Typography className="errorMessage">
                    {this.state.phoneNumberStatus}
                  </Typography>                 
                </Grid>
                <Grid item xs={12} sm={6}>
                <label className="labelText">Employee Email <span style={{ color: 'red' }}>*</span></label>
                {console.log("log222",Boolean(this.state.emailError))}
                  <CustomTextField
                    fullWidth
                    margin="normal"
                    name="employeeEmail"
                    placeholder="Employee Email"
                    value={employeeEmail}
                    onChange={this.handleChange}
                    variant="outlined"
                    className="textName"
                    error={Boolean(this.state.emailError)}
                    helperText={this.state.emailError}
                    data-testId="empEmialTest"
                  />
                </Grid>
                </Grid>
                <Grid className='invitebtns' style={{marginTop:"24px"}} container spacing={2}>
                  <Grid item>
                    <Button data-testId="cancelButtonTest" onClick={() => this.goToScreen("PeopleManagement")} className='btnIEmp' variant="contained">
                      <Typography>Cancel</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button data-testId="inviteEmployeeTEstID" className='btnIEmp colorBtn' variant="contained" disabled={this.state.isButtonDisabled} onClick={this.handleInvite}>
                      <Typography>{this.state.Loader ? <CircularProgress thickness={4} size={30} /> : "Send Invite"}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </BoxContainer>
          </StyledContainer>

            <SuccessDialog
                disc={configJSON.sucessMsg}
                open={this.state.successDialogOpen}
                onClose={this.handleCancel}
            />
        </Headers>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledContainer = styled(Container)({
  height:"100vh",
  maxWidth:"100vw !important",
  backgroundColor: "#242424",
  paddingTop: "24px"
})
const BoxContainer = styled(Box)({
  height:"496px",
  top:"120px",
  "& .labelText":{
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color:"#EFEFEF"
  },
  "& .textName":{
    color:"white",
    '& .MuiOutlinedInput-root': {
      '& .MuiInputBase-input::placeholder': {
        color: '#7C7C7C',
        opacity: 0.3,
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Lato, sans-serif',
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "-0.01em",
        textAlign: "left",
        color: "#D3D3D3"
      },
    },
  },
  "& .headerBox":{
    margin:"0px 0px 32px 0px",
  },
  "& .headerTxt":{
    fontFamily:"Lato",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: '48px',
    letterSpacing: "-0.02em",
    textAlign: "left",
    color:"#FCFCFC"
  },
  "& .btnIEmp":{
    width:"162px",
    height:"48px",
    padding:"12px 20px",
    background:"transparent",
    border:"1px solid",
    borderRadius:"0px",
    textTransform: "capitalize",
    transition: "background 0.3s ease" ,
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "center",
  },
  "& .btnIEmp:hover": {
    background: "#0F0"
  }, 
  "& .colorBtn":{
    background: "#0F0",
    color:" black"
  },
  "& .errorMessage": {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  "& .react-tel-input .flag-dropdown": {
    background: "none",
    border: "none",
  },
  "& .react-tel-input": {
    width: "30%",
    height: "46px",
  },
  "& .react-tel-input .form-control": {
    width: "74%",
    paddingLeft: "28px",
    backgroundColor: "rgb(37 37 37) !important",
  },
  "& .react-tel-input .selected-flag": {
    width: "0px",
    padding: "0px",
    borderRadius: "0px",
  },

  "& .react-tel-input .country-list": {
    zIndex: 37,
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    width: "377px",
    borderRadius: "7px",
    backgroundColor: "#2f2f2f !important",
  },
  "& .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled ":{
    color:"#FEFEFE"
  },
  "& .backimg":{
    width: "40px",
    height: "40px",
    color: "white"
  },
  "@media (max-width: 768px)": {
    "& .invitebtns": {  
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    "& .headerTxt":{
      fontSize:"32px"
    },
    "& .backimg":{
      width:"32px",
      height:"32px"
    }
  },

  "@media (max-width: 480px)": {
    border:"1px solid blue",
    "& .headerTxt":{
      fontSize:"24px"
    },
    "& .backimg":{
      width:"24px",
      height:"24px"
    }
  }
})

const CustomTextField = styled(TextField)(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: error ? "red !importent" : theme.palette.grey[400]
    },
    '&:hover fieldset': {
      borderColor: error ? "red" : theme.palette.grey[600],
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? "red" : theme.palette.grey[400]
    },
  },

}));
// Customizable Area End
