// Customizable Area Start
import React from "react";
import {

  Box,
  Typography,
  Grid,
  TextField,
  Select,
  Modal,
  Paper,
  IconButton,
  Button,
  Input
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { styled } from '@material-ui/styles'
import ArrowIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
  configJSON,
} from "./CustomFormController.web";
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web';
import CustomCalender from '../../../components/src/CustomCalender.web'
import ImageOcr from '../../../components/src/ImageOcr'
import CircularProgress from '@material-ui/core/CircularProgress';
const profileImg = require("../assets/blankProfile.jpeg");
const successIcon = require("../assets/sucess_icon.png");
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderUserProfileImage = () => {
    const { selectedImage } = this.state;
    const imageSrc = selectedImage ? URL.createObjectURL(selectedImage) : profileImg;
    return (
      <>
        <Box
          onClick={this.onChooseFile}
          className="userImage" data-testId="avatarBoxTestId"
          style={{
            border: (this.state.imgSizeExceed || this.state.invalidFileType) ? "3px solid red" : "3px solid #00FF00"
            }}
          >        
          <img src={imageSrc} alt="" className="userProfileImage" />        
          <input
            onChange={this.handleAvatarFileChange}
            data-testId="setUserProfileTestId" ref={this.fileInputRef}
            type="file"
            className="imgUploadInput"
            hidden />
        </Box>
        <Typography className="uploadTextThree" style={{color: (this.state.imgSizeExceed || this.state.invalidFileType) ? "red" : "#7C7C7C" }}>{configJSON.uploadTextTwo}</Typography>
      </>
    );
  };

  renderDocumentType = () => {
    return (
      <Modal open={this.state.documentTypeModal}>
        <SelectTypeModal>
          <Paper className='modalFormContainer'>          
            <Box className="documentSection">
              <label className="labelName">{configJSON.documentType}</label>
              <TextField
                className="textFieldSection"
                value={this.state.documentType}
                onChange={this.handleDocumentType}
                data-testId="ducumentTypeTestId"
                variant='outlined'
                fullWidth
                placeholder='Passport'
              />
              <Box className="usersButton">
                <Button className="uploadBtn" onClick={this.handleUploadDocument2}  data-testId="uploadbutton"  disabled={this.state.documentType == ""}>{configJSON.uploadBtn}</Button>               
              </Box>
            </Box>
          </Paper>
        </SelectTypeModal>
      </Modal>
    )
  };

  renderSuccessUploaded = () => {
    return (
      <Modal open={this.state.successModal}>
        <SelectTypeModal>
          <Paper className='modalFormContainer'>
            <Box className='modalFormTopSection'>
              <IconButton onClick={this.handleSuccessModalClose}><CloseIcon className='closeIcon' /></IconButton>
            </Box>
            <Box className="userSection">
               <img src={successIcon} alt="default profile" className=""/>
              <Typography className="userDocumentText">{configJSON.successMessage}</Typography>
            </Box>
          </Paper>
        </SelectTypeModal>
      </Modal>
    )
  }

  renderUploadPictureModal = () => {
    return (
      <Modal open={this.state.imageModal}>
        <UploadPictureModal>
          <Paper className='modalFormContainer'>
            <Box className='modalFormTopSection'>
            <IconButton data-testId="handleCloseTestId" className="iconButton" onClick={this.handleModalClose}><CloseIcon className='closeIcon' /></IconButton>            </Box>
            <Box className='modalForm'>
              <Box className="passportUpload">
                <Typography className="uploadHeading">{configJSON.uploadButton}</Typography>
                <Box
                data-testId="uploadTestId"
                  onDragOver={this.handleDragOver}
                  onDrop={this.handleDrop}
                  className="uploadHeadingCheck"
                >
                  {this.state.draggedFile ? (
                    <Box>
                      <p>{this.state.draggedFile.name}</p>
                      <img src={URL.createObjectURL(this.state.draggedFile)} alt="Dropped File" className="draggedFileOption" />
                    </Box>
                  ) : (
                    <Typography className="uploadText">{configJSON.dragText}</Typography>
                  )}
                </Box>
                <Box className='browseImage'>
                  <AddIcon />
                  <Button data-testId="browserTestId" className="browseButton" onClick={this.handleButtonClick}>{configJSON.browserBtn}</Button>                </Box>
                <Box>
                  {this.state.files.map((file, index) => (
                    <Box key={index}>
                      {file.name}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Paper>
        </UploadPictureModal>
      </Modal>
    )
  }

  renderUsersForm = () => {
    return (
      <div style={{height:"100%"}}>
      <UsersForm style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
        <form data-testId="formDataTestId" onSubmit={this.handleVerify} className="formContainer">
          <Box>
            <Box className='headerSection'>
              <IconButton onClick={this.customFromGoBack}>
                <ArrowIcon className="backBtn" />
              </IconButton>
              <Typography className="userHeading">{configJSON.userName}</Typography>
            </Box>
            {this.renderUserProfileImage()}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.firstName}</label>
                <Input
                  className="textFieldSection"
                  name="firstName"
                  value={this.state.userState.firstName}
                  onChange={this.handleChange}
                  data-testId="firstNameTestId"
                  fullWidth
                  disableUnderline
                  placeholder='Enter first name'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.middleNames}</label>
                <Input
                  className="textFieldSection"
                  name="middleName"
                  value={this.state.userState.middleName}
                  onChange={this.handleChange}
                  data-testId="middleNameTestId"
                  fullWidth
                  disableUnderline
                  placeholder='Enter middle name'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.middleName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.lastName}</label>
                <Input
                  className="textFieldSection"
                  name="lastName"
                  value={this.state.userState.lastName}
                  onChange={this.handleChange}
                  data-testId="lastNameTestId"
                  disableUnderline
                  fullWidth
                  placeholder='Enter last name'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.dateOfBirth}</label>
                <CustomCalender
                  open={this.state.open}
                  selectedDate={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  onClick={this.handleOnclick}
                  data-testId="dateOfBirthTestId"
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.dateOfBirth}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.zipCode}</label>
                <Input
                  name="zipCode"
                  value={this.state.userState.zipCode}
                  onChange={this.handleChange}
                  data-testId="zipCodeTestId"
                  className="textFieldSection"
                  fullWidth placeholder='Enter Zip Code'
                  disableUnderline
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.zipCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.nationalityName}</label>
                <Input
                  name="nationality"
                  value={this.state.userState.nationality}
                  onChange={this.handleChange}
                  data-testId="nationalityTestId"
                  className="textFieldSection"
                  fullWidth
                  placeholder='Select nationality'
                  disableUnderline
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.nationality}
                </Typography>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>          
                <InputLabel className="labelName" >{configJSON.selectCountry}</InputLabel>          
                 <Select                                            
                  onChange={this.handleChange}
                  className="textFieldSection"
                  style={{width:"100%"}}
                  data-testId="countryTestId"
                  disableUnderline
                  label={configJSON.selectCountry}
                  name="countryAlpha"
                  value={this.state.userState.countryThreeDigit || ""}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 400, 
                        backgroundColor: "#212521", 
                      },
                    },
                  }}
                >
                   <MenuItem value="" disabled style={{backgroundColor:"#212521"}}>
                     <div style={{color:"#838383"}}>Select Country</div> 
                   </MenuItem> 
                  {Object.entries(configJSON.countries).map(([code, name]) => (
                     <MenuItem key={code} value={code} style={{color:"#ffffff",backgroundColor:"#212521"}}>
                     {name as string}
                     </MenuItem>
                   ))}
                  </Select>       
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.adressFirst}</label>
                <Input className="textFieldSection"
                  name="addressOne"
                  value={this.state.userState.addressOne}
                  onChange={this.handleChange}
                  onBlur={this.handleAddressBlur}
                  data-testId="addressTestId"
                  fullWidth placeholder='Enter Address'
                  disableUnderline
                />
                <Typography className="formErrorStyle">
                  {this.state.addressError ? "Address cannot be more than 52 letter" : null}
                  {this.state.userFormError.addressOne}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.secondAdress}</label>
                <Input className="textFieldSection"
                  name="addressTwo"
                  value={this.state.userState.addressTwo}
                  onChange={this.handleChange}
                  onBlur={this.handleAddressTwoBlur}
                  data-testId="addresstwoTestId"
                  disableUnderline
                  fullWidth
                  placeholder='Enter Address'
                />
                <Typography className="formErrorStyle">
                  {this.state.adressTwoError ? "Address cannot be more than 52 letter" : null}      
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.stateSelect}</label>
                <Input className="textFieldSection"
                  name="state"
                  value={this.state.userState.state}
                  onChange={this.handleChange}
                  onBlur={this.handleAddressTwoBlur}
                  data-testId="stateTestId"
                  disableUnderline
                  fullWidth
                  placeholder='Enter State'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.state}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.citySelect}</label>
                <Input className="textFieldSection"
                  name="city"
                  value={this.state.userState.city}
                  onChange={this.handleChange}
                  onBlur={this.handleAddressTwoBlur}
                  data-testId="cityTestId"
                  disableUnderline
                  autoComplete="off"
                  fullWidth
                  placeholder='Enter City'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.city}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <label className="labelName">{configJSON.passportNumber}*</label>
                <Input
                  className="textFieldSection"
                  name="passportNumber"
                  value={this.state.userState.passportNumber}
                  onChange={this.handleChange}
                  data-testId="passportTestId"
                  disableUnderline
                  fullWidth
                  placeholder='Enter Passport Number'
                />
                <Typography className="formErrorStyle">
                  {this.state.userFormError.passportNumber}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className="passportUpload" data-testId="opneModalTestId" onClick={() => this.handleModalOpen("passport", "passportInputId")} style={{ border:!this.state.invalidFileType2 ?  "2px dashed #D3D3D3": "2px dashed red"}}>
            <img src={!this.state.invalidFileType2 ? require("../assets/blackuploadIcon.svg") : require("../assets/reduploadIcon.svg")} className="uploadImage" />
            <Typography className="uploadHeading" style={{color: !this.state.invalidFileType2 ? "#D3D3D3":"red"}}>{configJSON.uploadPassport}</Typography>
            <Typography className="uploadText" style={{color: !this.state.invalidFileType2 ? "#D3D3D3":"red"}}>{configJSON.uploadText}</Typography>
            <Box>
              <Box
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                onClick={this.handleButtonClick}
              >
              </Box>
              <input
                type="file"
                id={"passportInputId"}
                multiple
                style={{ display: 'none' }}
                data-testId="passPortInputValueDataTestId"
                onChange={this.handlePassportFileInput}
              />
            </Box>
            <Box data-testId="passportFileNameTestId">
              {this.handleFileNameLength(this.state.passport?.name)}
            </Box>
          </Box>
          <Typography className="formErrorStyle">
            {this.state.userFormError.passportNumber}
          </Typography>
          <Box className="usersButton">
            <Button onClick={this.handleRemoveData} className="cancelBtn"  data-testId="cancelBtnID">{configJSON.cancelBtn}</Button>
            <Button disabled={this.handleActiveButton()==="verifyBtn"?true:false} className={this.handleActiveButton()} type="submit"  onClick={this.verifyUserDoc} data-testId="verifyUserDocBtnID" >{this.state.Loader ? <CircularProgress thickness={4} size={30} /> : configJSON.verifyText}</Button>
          </Box>
        </form>
        {this.renderUploadPictureModal()}
        {this.renderSuccessUploaded()}
        <ImageOcr file={this.state.passport} updateOcrData={this.updateOcrData} />


      </UsersForm>
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {
          this.state.role === "company" ? <Headers isRouteData={"CustomForm"} sliderButton={this.state.sideBarClose}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          onToggleClick={this.handleSliderButton}
          goToScreen = {this.goToScreen}
          >
            {this.renderUsersForm()}
            
        </Headers> 
        :

        <EmployeHeader isRouteData={"CustomForm"} 
        sliderButton={this.state.sideBarClose}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          onToggleClick={this.handleSliderButton}
          goToScreen={this.goToScreen}
        >
          {this.renderUsersForm()}
        </EmployeHeader>
        }
      </>
      
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const UsersForm = styled(Box)({
  padding: "20px",
  background: "#242424",
  color: "white",
  fontFamily: 'Lato',
  "& .formContainer": {
    margin: "0px",
    fontFamily: 'Lato',
  },
  "& .headerSection": {
    display: "flex",
    gap: "25px",
    alignItems: "center",
    height: "40px",
  },
  "& .formErrorStyle": {
    color: "red",
    fontSize: "14px",
    marginBottom: "5px",
  },
  "& .userHeading": {
    fontSize: "40px",
    fontWeight: 600,
    fontFamily: 'Lato',
    whiteSpace:"nowrap"
  },
  "& .backBtn": {
    height: "40px",
    width: "40px",
    color: "white"
  },
  "& .textFieldSection": {
    marginTop: '15px',
    marginBottom: "5px",
    border: "1px solid #D3D3D3",
    fontWeight: 600,
    height: "56px",
    fontFamily: 'Lato',
    "& .MuiInputBase-input": {
      color: "#fff",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      padding: "12px",
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },

  },
  "& .selectFieldSection": {
    marginTop: '15px',
    marginBottom: "5px",
    height: "56px",
    border: "1px solid #D3D3D3",
    fontWeight: 600,
    color: "white",
    "& .MuiSelect-icon": {
      color: "#505050",
      marginRight: "15px"
    },
    "& .MuiInputBase-input": {
      color: "#524d4d",
      paddingLeft: "15px",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      padding: "12px",
    },

    "&.MuiInput-underline::after": {
      borderBottom: "0px",
    },
    "&.MuiOutlinedInput-root": {
      "& .defaultSelect": {
        color: "#7C7C7C"
      }
    },
    "&.MuiInput-underline::before": {
      borderBottom: "0px",
    },
    "& .MuiSelect-select": {
      color: "white",
      "& .defaultSelect": {
        color: "#7C7C7C"
      }
    },
  },
  "& .passportUpload": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: '20px',
    marginTop: "15px",
    height: "162px",
    cursor:"pointer"
  },
  "& .mainDocument": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  "& .documentUpload": {
    border: "2px dashed #D3D3D3",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "15px",
    height: "136px",
    width: "30%",
    color: "rgba(167, 167, 167, 1)",
    padding: "12px 12px 12px 12px",
    cursor:"pointer"
  },
  "@media (max-width: 768px)": {
    "& .documentUpload": {
      width: "50%",
      height: "auto",
      justifyContent: "center",
    }
  },
  "@media (max-width: 480px)": {
    "& .documentUpload": {
      width: "100%",
      marginTop: "10px",
    },
    "& .mainDocument": {
      justifyContent: "center",
    },
  },
  "& .cancelBtn": {
    color: "#00FF00",
    border: "1px solid white",
    width: "162px",
    height: "48px",
    textTransform: "capitalize",
    padding: "12px 20px",
    fontFamily: 'Lato',
  },
  "& .verifyBtn": {
    background: "#7C7C7C",
    padding: "12px 20px",
    border: "none",
    width: "162px",
    height: "48px",
    textTransform: "capitalize",
    fontFamily: 'Lato',
  },
  "& .verifyBtnActive": {
    background: "#00FF00",
    width: "162px",
    height: "48px",
    border: "none",
    padding: "12px 20px",
    textTransform: "capitalize",
    fontFamily: 'Lato',

  },
  "& .usersButton": {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
  },
  "& .labelName": {
    color: "#EFEFEF",
    lineHeight: "24px",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: 'Lato',
    "& .MuiInputBase-input": {
      color: "#fff",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "24px"
    }
  },
  "& .inputDateField": {
    background: "transparent",
    color: "#fff",
    marginBottom: "5px",
    height: "56px",
    border: "1px solid #D3D3D3",
    padding: "0px 10px",
    marginTop: "15px",
  },
  "& .inputDateFieldGray": {
    background: "transparent",
    color: "#7C7C7C",
    height: "56px",
    border: "1px solid #D3D3D3",
    padding: "0px 10px",
    marginTop: "15px",
    marginBottom: "5px",
  },
  "& .inputFieldWrapper .MuiInputAdornment-positionEnd": {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "8px",
    marginTop: "15px",
    marginBottom: "5px",
  },
  "& .userProfileImage": {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    cursor:"pointer"
  },
  "& .userImage": {
    width: "96px",
    height: "96px",
    border: "3px solid #00FF00",
    borderRadius: "50%",
    marginTop: "25px",
    marginBottom: "10px",
    cursor:"pointer"
  },
  "& .uploadTextThree":{
    color:" #7C7C7C",
    fontSize: "14px",
    fontFamily: "Lato",
    fontWeight: 400,
    marginBottom:"10px",
     lineHeight: "22px",
  },
  "& .uploadHeading": {
    color: "#A7A7A7",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: 'Lato',
  },
  "& .uploadText": {
    color: "#7C7C7C",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: 'Lato'
  },
  "&.uploadHeadingCheck": {
    width: '300px',
    textAlign: "center"
  },
  "& .draggedFileOption": {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  "& .adharInputField": {
    display: 'none'
  },
  "& .uploadImage": {
    width: "32px",
    height: "32px"
  },
  "& .calenderIcon": {
    height: "24px",
    width: "24px"
  },
})
const UploadPictureModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  overflow: "scroll",
  fontFamily: 'Lato',
  color: "#D3D3D3",
  "& .modalFormContainer": {
    width: "30%",
    background: "#505050",
  },
  "& .modalFormTopSection": {
    float: 'right',
    paddingTop: "15px",
    paddingRight: "15px",
    paddingLeft: "5px",
    paddingBottom: "5px"
  },
  "& .modalForm": {
    padding: "20px",
  },
  "& .passportUpload": {
    border: "2px dashed #D3D3D3",
    padding: '20px',
    height: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    flexDirection: "column",
    color: "#D3D3D3",
    margin: "20px"
  },
  "& .closeIcon": {
    color: "#D3D3D3",
  },
  "& .MuiIconButton-root": {
    padding: 0
  },
  "& .browseButton": {
    textTransform: "capitalize",
    color: "#00FF00",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px"
  },
  "& .browseImage": {
    display: "flex",
    alignItems: "center",
    color: "#00FF00"
  },
  "& .uploadHeading": {
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#D3D3D3",
  },
  "& .uploadText": {
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
    color: "#D3D3D3",
    fontFamily: 'Lato',
  },
})

const SelectTypeModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  fontFamily: 'Lato',
  overflow: "scroll",
  color: "#D3D3D3",
  "& .modalFormContainer": {
    width: "628px",
    background: "#505050",
    height: "254px",
  },
  "& .modalFormTopSection": {
    float: 'right',
    paddingTop: "15px",
    paddingRight: "15px",
    paddingLeft: "5px",
    paddingBottom: "5px"
  },
  "& .modalForm": {
    padding: "20px",
  },
  "& .textFieldSection": {
    marginTop: '15px',
    marginBottom: "5px",
    fontWeight: 600,
    "& .MuiInputBase-input": {
      color: "#D3D3D3",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D3D3D3"
    },
  },
  "& .passportUpload": {
    border: "2px dashed #D3D3D3",
    padding: '20px',
    height: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "15px",
    flexDirection: "column",
    color: "#D3D3D3",
    margin: "20px"
  },
  "& .closeIcon": {
    color: "#D3D3D3",
  },
  "& .MuiIconButton-root": {
    padding: 0
  },
  "& .browseButton": {
    textTransform: "capitalize",
    color: "#00FF00",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px"
  },
  "& .browseImage": {
    display: "flex",
    alignItems: "center",
    color: "#00FF00"
  },
  "& .uploadHeading": {
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#D3D3D3",
  },
  "& .uploadText": {
    fontWeight: 400,
    lineHeight: "22px",
    fontSize: "14px",
    color: "#D3D3D3",
  },
  "& .cancelBtn": {
    color: "#00FF00",
    border: "1px solid white",
    width: "162px",
    height: "48px",
    padding: "12px 20px",
    marginTop: "15px",
    textTransform: "capitalize",
  },
  "& .uploadBtn": {
    background: "#00FF00",
    border: "none",
    width: "162px",
    height: "48px",
    padding: "12px 20px",
    marginRight: "15px",
    marginTop: "15px",
    textTransform: "capitalize",
  },
  "& .MuiButton-root": {
    borderRadius: 0
  },
  "& .labelName": {
    color: "#D3D3D3",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px"
  },
  "& .usersButton": {
    float: "right",
    paddingBottom: "50px"
  },
  "& .documentSection": {
    padding: "50px",
  },
  "& .userSection": {
    textAlign: "center",
    padding: "50px",
  },
  "& .userDocumentText": {
    color: "#fff",
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "28px",
    marginTop: "40px"
  },
})

// Customizable Area End
