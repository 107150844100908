import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
//@ts-ignore
import { GetCountries, GetState } from "react-country-state-city";
import { countryData } from "../../../../packages/components/src/ClientGlobals";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
interface ZipCodeAttributes {
  id: number;
  zip_code: string;
  country_code: string;
  postal_area: string;
  city: string;
  state: string;
  country_full_name: string;
  nationality: string;
}

interface ZipCodeData {
  id: string;
  type: string;
  attributes: ZipCodeAttributes;
}

interface ZipCodeResponse {
  data: ZipCodeData[];
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface CityApi {
  place_id: number,
  licence: string
  latiTude: string,
  longiTude: string,
  class: string,
  type: string,
  place_rank: number,
  importance: number,
  addresstype: string,
  name: string,
  display_name: string,
  boundingbox: Array<string>
}

interface AccountSubscriptionAPi {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      price: string;
      description: string;
      store_credit_id: number;
      subscriptions_plan_benefit_id: [];
      image_link: string;      
    };
  }[];
}

interface ApiResponseError {
  errors: [{ [key: string]: string }]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  stepUpdate: any;
}
type InitialFalseTrue = "initial" | "false" | "true";
interface ErrorPasswordObject {
  alphabet: InitialFalseTrue;
  minimumChar: InitialFalseTrue;
  capitalChar: InitialFalseTrue;
  specialSign: InitialFalseTrue;
  number: InitialFalseTrue;
}

export interface S {
  // Customizable Area Start
  role: {
    company: boolean;
    employee: boolean;
  };
  formData: {
    companyName: string;
    email: string;
    personName: string;
    dailCode: string;
    phoneNumber: string;
    taxIdentificationNumber: number | string;
    websiteLink: string;
    companyAddress: string;
    street: string;
    countryName: string;
    cityNameCheck: string;
    stateNameCheck: string;
    zipCode: string;
    password: string;
    reTypePassword: string;
    errorEmail:string,
    error:string,
    countryThreeDigit:string,
  };
  employeeData: {
    companyId: string;
    contactNumber: string | number;
    dailNumber: string;
    employeeName: string;
    employeeEmail: string;
    empPassworrd: string;
    empConfirmPass: string;
    PhoneNumberValidationCheck:number;
  };
  employeErrorData: {
    empPassword: ErrorPasswordObject;
  };
  formErrorData: {
    firstName: string;
    lastName: string;
    email: string;
    password: ErrorPasswordObject;
    reTypePassword: string;
  };
  accountSubscription: {
    name: string,
    price: string,
    description: string,
    storeCreditId: number,    
    imageLink: string,
    subscriptionsPlanBenefitId: []
   },
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  step: number;
  countryId: string;
  stateID: number;
  btnStatus: boolean;
  phoneNumberStatus: string;
  secondBtnStatus: boolean;
  thirdBtnStattus: boolean;
  validPass: boolean;
  confirmPass: boolean;
  enableBtn: boolean;
  phoneNumber:string;
  phoneNumError: boolean;
  phoneCountryCode: CountryCodeObj;
  emailError: string;
  companyNameError:boolean;
  personNameError :boolean;
  companyAddressError:boolean;
  streetError :boolean;
  cityError :boolean;
  websiteLinkError: boolean;
  websiteLink:string;
  isHovered:boolean;
  isHoveredEmp:boolean;
  error:string;
  errorEmail:string
  cityNameCheck:string;
  isCheckPassword : boolean;
  Loader:boolean
  Loader2:boolean
  imageLoading: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  code: string;
  label: string;
  phone: string;
  id: any;
  name: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  companyCreateAccountApiCallId : string = "";
  accountSubscriptionApiCallId : string = "";
  getCityAPICallId : string = "";
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  emailRegex: RegExp;
  taxRegex:RegExp
  nameRegex: RegExp;
  passwordRegEx: RegExp;
  numberRegex: RegExp;
  checkAlphabet: RegExp;
  checkCapital: RegExp;
  checkDigit: RegExp;
  checkSpecialChar: RegExp;
  websiteRegex : RegExp;
  checkAlphaNumaricReg:RegExp;
  phoneNumberReg:RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.emailRegex =
    /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.nameRegex = /^[A-Za-z\s]+$/;
    this.numberRegex = /^\d+$/;
    this.taxRegex=configJSON.taxRegex;
    this.passwordRegEx =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}/;
    this.checkAlphabet = /[a-zA-Z]/;
    this.checkCapital = /[A-Z]/;
    this.checkDigit = /\d/;
    this.checkSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/    ;
    this.checkAlphaNumaricReg = /^[a-zA-Z0-9]+$/;
    this.phoneNumberReg = /^\d{10}$/;
    this.state = {
      // Customizable Area Start
      role: {
        company: false,
        employee: false,
      },
      formData: {
        companyName: "",
        personName: "",
        dailCode: "",
        phoneNumber: "",
        taxIdentificationNumber: "",
        websiteLink: "",
        companyAddress: "",
        street: "",
        email: "",
        password: "",
        reTypePassword: "",
        countryName: "",
        stateNameCheck: "",
        cityNameCheck: "",
        zipCode: "",
        error:"",
        errorEmail:"",
        countryThreeDigit:"",
      },

      employeeData: {
        companyId: "",
        contactNumber: "",
        dailNumber: "",
        employeeName: "",
        employeeEmail: "",
        empPassworrd: "",
        empConfirmPass: "",
        PhoneNumberValidationCheck:100,
      },
      formErrorData: {
        firstName: "",
        lastName: "",
        email: "",
        password: {
          alphabet: this.state?.formErrorData?.password?.alphabet
            ? "false"
            : "true",
          capitalChar: this.state?.formErrorData?.password?.capitalChar
            ? "false"
            : "true",
          minimumChar:
            this.state?.formData?.password.length >= 8 ? "false" : "true",
          number: this.state?.formErrorData?.password?.number
            ? "false"
            : "true",
          specialSign: this.state?.formErrorData?.password?.specialSign
            ? "false"
            : "true",
        },
        reTypePassword: "",
      },
      employeErrorData: {
        empPassword: {
          alphabet: this.state?.employeErrorData?.empPassword?.alphabet
            ? "false"
            : "true",
          capitalChar: this.state?.employeErrorData?.empPassword?.capitalChar
            ? "false"
            : "true",
          minimumChar:
            this.state?.formData?.password.length >= 8 ? "false" : "true",
          number: this.state?.employeErrorData?.empPassword?.number
            ? "false"
            : "true",
          specialSign: this.state?.employeErrorData?.empPassword?.specialSign
            ? "false"
            : "true",
        },
      },
      accountSubscription: {
                name: "",
                price: "",
                description: "",
                storeCreditId: 0,               
                imageLink: "",
                subscriptionsPlanBenefitId:[]
      },
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      step: 1,   
      countryId: "",
      stateID: 0,
      btnStatus: true,
      phoneNumberStatus: "",
      secondBtnStatus: true,
      thirdBtnStattus: true,
      validPass: true,
      confirmPass: false,
      enableBtn: true,
      phoneNumber:"",
      phoneNumError: false,
      phoneCountryCode: {} as CountryCodeObj,
      emailError: "",
      companyNameError :false,
      personNameError :false,
      companyAddressError:false,
      streetError :false,
      cityError :false,
      websiteLinkError: false,
      websiteLink:"",
      isHovered:true,
      isHoveredEmp:true,
      error:"",
      errorEmail:"",
      Loader:false,
      Loader2:false,
      cityNameCheck:"",
      isCheckPassword : false,
      imageLoading: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {  
    super.componentDidMount();
    this.getAccountSubscription();
  }  

  accountSubscriptionFunction=(responseJson:any)=>{
    this.accountSubscriptionAPIResponse(responseJson) 
   }
   handelCityAPIFunction=(responseJson:any)=>{
    this.handelCityAPIResponse(responseJson) 
   }

  async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiIdList = {
          [this.accountSubscriptionApiCallId]: this.accountSubscriptionFunction,      
          [this.getCityAPICallId]: this.handelCityAPIFunction,          
        }
        let functionName = apiIdList[apiRequestCallId]
        if (functionName)
          functionName(responseJson)
  
        if (apiRequestCallId === this.createAccountApiCallId) {
           this.myfunc(responseJson)
        } 
        
        else if (apiRequestCallId === this.companyCreateAccountApiCallId) {
          this.secondFunc(responseJson)
        }
      }
    // Customizable Area End
  }

  secondFunc = async(responseJson:any) =>{
    this.setState({ Loader: false })  
    if(responseJson&&responseJson?.data&&responseJson?.data&&responseJson?.data?.id!==""){
      toast.success("Company created successfully",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    }
    if (responseJson && responseJson?.data?.attributes && responseJson?.data?.attributes?.user_type) {
      await setStorageData("user_type", responseJson.data.attributes.user_type)
      await setStorageData("email",responseJson.data.attributes.email)
      this.handleNaviagate("TermsConditions")
    }
    if(responseJson && responseJson.errors == "Email already taken by employee"){
      let btm = true;
      {this.handleEmployeeErrorCheck(responseJson.errors)}
    }
    if (responseJson && responseJson?.errors) {
      const errorMessages: string[] = responseJson.errors.map((error: { [key: string]: string }) => Object.values(error)).flat();
     if(errorMessages.includes("must be unique. This email is already exist.")){
      {    
        this.handelforAllCompanyErrorCheck()
      }
     }else if(errorMessages.includes("This company name is already exist.")){
      this.handelforAllCompanyNameErrorCheck()
     }
      else {
        this.stepCount();
      }
    }
  }


 myfunc = async (responseJson:any) => {
  this.setState({ Loader: false })  
  if (responseJson && responseJson?.data?.attributes && responseJson?.data?.attributes?.user_type) {
    await setStorageData("user_type", responseJson.data.attributes.user_type)
    toast.success("Employee created successfully",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    this.handleNaviagate("TermsConditions")
  }
  if (responseJson && responseJson?.errors) {
    if(responseJson?.errors === "Email already taken by company"){
      toast.error(responseJson?.errors,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} );
      this.setState({ errorEmail: "Email already taken by company",btnStatus:true });
      this.setState({ error: "" });
    }
    const errorMessages: string[] = responseJson.errors.map((error: { [key: string]: string }) => Object.values(error)).flat();
    if (errorMessages.includes("Company does not exist")) {
      { this.handelCompanyCheck()}
    } else if (errorMessages.includes("Employee with this email already exists")) {
      { this.handelCompanyErrorCheck()}
    } else {
      this.stepCount();
    }
  }
  } 

  handleEmployeeErrorCheck = (btm?: any) =>{
    this.setState({ error: `${btm}`,btnStatus:true });
    this.setState({ errorEmail: `${btm}` });
  }


  handelCompanyCheck = () => {
    this.setState({ error: "Company does not exist.",btnStatus:true });
    this.setState({ errorEmail: "" });
  }
  handelCompanyErrorCheck = () => {
    toast.error("Email ID Already Exist !",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
    this.setState({ errorEmail: "Employee with this email already exists",btnStatus:true });
    this.setState({ error: "" });
  }

  handelforAllCompanyErrorCheck = () => {
    this.setState({errorEmail: "Company with this email already exists",btnStatus:true });
  }
  
  handelforAllCompanyNameErrorCheck = () => {
    this.setState({error: "Company Name already exists.",btnStatus:true });
  }

  handelCityAPIResponse =(responseJson:ZipCodeResponse)=>{
    if(responseJson.data.length===0){
      toast.error("Zip code not found",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ thirdBtnStattus: true, Loader: false  });
      return
    }
    const details = responseJson.data[0].attributes;
    this.setState({
      formData:{
        ...this.state.formData,
        cityNameCheck: details.city,
        stateNameCheck:details.state,
        countryThreeDigit:details.country_code,
        countryName:details.country_full_name,
     },
     thirdBtnStattus: false
    });    
  }
  
 
  accountSubscriptionAPIResponse = async(responseJson: AccountSubscriptionAPi) => {
    const attributes = responseJson.data[0].attributes;
    await setStorageData("payment_id",responseJson.data[0].id)
    await setStorageData("payment_price",attributes.price)
    await setStorageData("payment_type",responseJson.data[0].type)
    this.setState({
      accountSubscription: {
        ...this.state.accountSubscription,
        name: attributes.name,
        price: attributes.price,
        description: attributes.description,
        storeCreditId: attributes.store_credit_id,        
        imageLink: attributes.image_link,
        subscriptionsPlanBenefitId:attributes.subscriptions_plan_benefit_id
      },
    });
  };
  
  handleCompanyCreateAccountResponse(responseJson: ApiResponseError) {
    if (responseJson && !responseJson.errors) {
      this.handleNaviagate("TermsConditions");
    }
  }

  
  handleNaviagate=(pathName:string)=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleSelectRoleNavigation=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectRole");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  createAccount = () => {
    this.setState({ Loader: true })  
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const dataTest = {
      data:{
      role_name: "company",
      company_name: this.state.formData.companyName,
      email: this.state.formData.email,
      full_phone_number:
      this.state.employeeData.dailNumber,
        contact_person_name: this.state.formData.personName,
      tax_identification_number: this.state.formData.taxIdentificationNumber,
      website_link: this.state.formData.websiteLink,
      address: this.state.formData.companyAddress,
      street: this.state.formData.street,
      city: this.state.formData.cityNameCheck,
      zip_code: this.state.formData.zipCode,
      state: this.state.formData.stateNameCheck,
      country: this.state.formData.countryName,
      password: this.state.formData.password,
      password_confirmation: this.state.formData.reTypePassword,
  }
};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.companyCreateAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySignupEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataTest)
    );
    

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createEmployeeAccount = () => {
    this.setState({ Loader: true })  
    const {
      companyId,
      employeeName,
      contactNumber,
      dailNumber,
      empPassworrd,
      empConfirmPass,
      employeeEmail,
    } = this.state.employeeData;
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const data = {
      company_unique_id: companyId,
      role_name: "employee",
      employee_name: employeeName,
      email: employeeEmail,
      full_phone_number: dailNumber,
      password: this.state.step==1 ? "": empPassworrd,
      password_confirmation: this.state.step==1 ? "": empConfirmPass,
    };
    const httpBody = {
      data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employeeSignupEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getAccountSubscription = () => {   
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  stepCount = () => {
        if (this.state.step <= 6) {
      this.setState({
        step: this.state.step + 1,
      },()=>{
     this.checkValidation();
      })
    }
  };

  handlePasswordShow = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleConfirmPasswordShow = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField,
    });
  };
  updateState = (e: any) => {
    this.setState({
      formData: {
        ...this.state.formData,
        stateNameCheck: e.target.value,
      },
    });
  };

  handleChecked = (
    type: "alphabet" | "number" | "capitalChar" | "specialSign" | "minimumChar"
  ) => {
    return this.state?.formErrorData?.password?.[type] === "false"
      ? true
      : false;
  };

  employeePasswordCheck = (
    type: "alphabet" | "number" | "capitalChar" | "specialSign" | "minimumChar"
  ) => {
    return this.state?.employeErrorData?.empPassword?.[type] === "false"
      ? true
      : false;
  };
  handlePasswordError = (password: any) => {
    let formErrorData: any = this.state.formErrorData;
    if (password.length > 0 && password) {
      let alphabet = this.checkAlphabet.test(password);
      let capitalChar = this.checkCapital.test(password);
      let number = this.checkDigit.test(password);
      let specialSign = this.checkSpecialChar.test(password);
      formErrorData = {
        ...formErrorData,
        password: {
          alphabet: alphabet ? "false" : "true",
          capitalChar: capitalChar ? "false" : "true",
          minimumChar: password.length >= 8 ? "false" : "true",
          number: number ? "false" : "true",
          specialSign: specialSign ? "false" : "true",
        },
      };
    } else {
      formErrorData = {
        ...formErrorData,
        password: {
          alphabet: "true",
          capitalChar: "true",
          minimumChar: "true",
          number: "true",
          specialSign: "true",
        },
      };
    }
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, password: password },
      formErrorData: formErrorData,
    });
  };
  employeePasswordError = (empPassword: string) => {
    let employeeErrorData: any = this.state.employeErrorData;
    if (empPassword.length > 0 && empPassword) {
      let alphabet = this.checkAlphabet.test(empPassword);
      let capitalChar = this.checkCapital.test(empPassword);
      let number = this.checkDigit.test(empPassword);
      let specialSign = this.checkSpecialChar.test(empPassword);
      employeeErrorData = {
        ...employeeErrorData,
        empPassword: {
          alphabet: alphabet ? "false" : "true",
          capitalChar: capitalChar ? "false" : "true",
          minimumChar: empPassword.length >= 8 ? "false" : "true",
          number: number ? "false" : "true",
          specialSign: specialSign ? "false" : "true",
        },
      };
    } else {
      employeeErrorData = {
        ...employeeErrorData,
        password: {
          alphabet: "true",
          capitalChar: "true",
          minimumChar: "true",
          number: "true",
          specialSign: "true",
        },
      };
    }
    this.setState({
      ...this.state,
      employeeData: { ...this.state.employeeData, empPassworrd: empPassword },
      employeErrorData: employeeErrorData,
    });
  };

  handleFormData = (e:  { target: { name: string, value: string } }) => {
    if (e?.target?.name === "password") {
      this.handlePasswordError(e.target.value);
      this.handlePasswordValidtion(e.target.value);
    } else if (e?.target?.name === "reTypePassword") {
      this.confirmPasswordValidation(
        e.target.value,
        this.state.formData.password
      );
    } else {
      this.setState(
        (prev) => ({
          ...prev,
          formData: {
            ...prev.formData,
            [e?.target?.name]: e?.target?.value,
          },
        }),
        () => {
            this.checkValidation();
            
        }
      );
    }
  };

  getCityDetail = async (zipCode:string) => {
    if(zipCode) {
      this.getCityAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.apiMethodTypeAddDetail,
        endPoint: `account_block/zip_code_search?query=${zipCode}`,
      });
    }
  };

  
  
  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleKeyDown = (e: any) => {
    const { name } = e.target;
    if (
      (
        name === "contactNumber" ) &&
      !this.numberRegex.test(e.key) &&
      !["ArrowLeft", "ArrowRight", "Delete", "Backspace", "Tab"].includes(e.key)
    ) {
      e.preventDefault();
    }
    if (
      (name === "personName" || name === "employeeName") &&
      !this.nameRegex.test(e.key) &&
      !["ArrowLeft", "ArrowRight", "Delete", "Backspace", "Tab"].includes(e.key)
    ) {
      e.preventDefault();
    }
    if((name === "companyId") &&  !this.checkAlphaNumaricReg.test(e.key) &&
    !["ArrowLeft", "ArrowRight", "Delete", "Backspace", "Tab"].includes(e.key))
    {
      e.preventDefault();
    }
    if((name === "phoneNumber") &&  !this.phoneNumberReg.test(e.key) &&
    !["ArrowLeft", "ArrowRight", "Delete", "Backspace", "Tab"].includes(e.key))
    {
      e.preventDefault();
    }
    if((name === "employeeEmail") && !this.state.btnStatus &&
    ["Enter"].includes(e.key))
    {    
      e.preventDefault();
      this.createEmployeeAccount()
    }
  };

  handleEmployeeData = (e: { target: { name: string, value: string } }) => {
    const inputValue = e.target.value;
    if (e.target.name == "empPassworrd") {
      this.employeePasswordError(e.target.value);
      this.handlePasswordValidtion(e.target.value);
    } else if (e?.target?.name === "empConfirmPass") {
      this.empPasswordConfirm(
        e.target.value,
        this.state.employeeData.empPassworrd
      );
    }else if (e.target.name == "employeeEmail"){
      this.setState({ errorEmail: "" });
      this.handleEmailCheck(inputValue, e.target.name)
    }else if(e.target.name == "employeeName"){  
      if(e.target.value.length<53){
        this.handleCompanyNameCheck(inputValue, e.target.name)
        this.employeeValidation();
      }
    }  
    if ((e.target.name === "employeeName" && e.target.value.length < 53) || e.target.name != "employeeName") {
      this.setState(
        (prevEmployeeData) => ({
          ...prevEmployeeData,
          employeeData: {
            ...prevEmployeeData.employeeData,
            [e.target.name]: e.target.value.trimStart(),
          },
        }),
        () => {
          this.employeeValidation();
        }
      );
      this.setState({ error: "" });
    }
  };

  dailcodeData = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let dailCodeLenght= await data?.dialCode?.length;
    let numberData = await data?.format?.split('.').length - 1;
    let name= await data?.name; 
    let reference = this.state.employeeData
    reference.dailNumber = value    
    reference.PhoneNumberValidationCheck = numberData
   
    this.setState({
      employeeData: reference,
    }, () => {
      this.checkValidation();   
      if (this.state.employeeData.dailNumber.length !== this.state.employeeData.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${numberData-dailCodeLenght} digits instead of ${this.state.employeeData.dailNumber.length-dailCodeLenght}`});
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });

    this.handleContactNumberChange(event.target.value, data);
  }; 
  dailcodeData2 = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let numberData = await data?.format?.split('.').length - 1;
    let name= await data?.name; 
    let dailCodeLenght= await data?.dialCode?.length;
    let reference = this.state.employeeData
    reference.dailNumber = value    
    reference.PhoneNumberValidationCheck = numberData   
    this.setState({
      employeeData: reference,
    }, () => {
      this.employeeValidation(); 
      if (this.state.employeeData.dailNumber.length !== this.state.employeeData.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${numberData-dailCodeLenght} digits instead of ${this.state.employeeData.dailNumber.length-dailCodeLenght}`});
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });

    this.handleContactNumberChange(event.target.value, data);
  }; 
  

  employeeValidation = () => {   
    const { companyId, employeeName, employeeEmail ,dailNumber,PhoneNumberValidationCheck} =   
      this.state.employeeData;
       if (
      companyId.match(this.checkAlphaNumaricReg) &&
      employeeName.match(this.nameRegex) &&
      employeeEmail.match(this.emailRegex) &&     
      dailNumber.length === PhoneNumberValidationCheck &&
      employeeName.length < 52

    ) {
      this.setState({ btnStatus: false });
    } else {
      this.setState({ btnStatus: true });
    }
  };
  empPasswordConfirm = (cpass: string, pass: string) => {
     this.setState({
      employeeData: {
        ...this.state.employeeData,
        empConfirmPass: cpass,
      },
    });
    if (cpass && pass && pass !== cpass) {
      this.setState({
        confirmPass: true,
        enableBtn: true
      });
    } else if(cpass && pass && cpass.length > 0 && pass.length > 0 && cpass === pass){
      this.setState({
        confirmPass: false,
        enableBtn: false
      });
    }else{
      this.setState({enableBtn:true})
    }
  };
  confirmPasswordValidation = (cpass: string, pass: string) => {
    this.setState({
      formData: {
        ...this.state.formData,
        reTypePassword: cpass,
      },
    });
    if (cpass && pass && pass !== cpass) {
      this.setState({
        confirmPass: true,
        isCheckPassword:false
      });
    } else if(cpass && pass && cpass.length > 0 && pass.length > 0 && cpass === pass) {
      this.setState({
        confirmPass: false,
        isCheckPassword:true
      });
    }else{
      this.setState({isCheckPassword:false})
    }
  };
  checkValidation = () => {
    const {
      companyName,
      email,
      personName,
      taxIdentificationNumber,
      websiteLink,
      companyAddress,
      street,
      countryName,
      zipCode,
    } = this.state.formData;
    if (this.emailRegex.test(email) && companyName.trim().length > 0 && personName.length > 0 && this.state.employeeData.dailNumber.length === this.state.employeeData.PhoneNumberValidationCheck
    ) {
      this.setState({ btnStatus: false });
    }
    else {
      this.setState({ btnStatus: true });
    }
    
    if (
    
      taxIdentificationNumber.toString().match(this.taxRegex) &&
      websiteLink.length > 0 &&
      this.websiteRegex.test(websiteLink) &&
      companyAddress.trim().length > 0 &&
      street.trim().length > 0 &&
      this.state.employeeData.dailNumber.length === this.state.employeeData.PhoneNumberValidationCheck
    ) {
      this.setState({ secondBtnStatus: false });
    } else {
      this.setState({ secondBtnStatus: true });
    }
    if (
      countryName.length > 0 && zipCode.length === 6
    ) {
      this.setState({ thirdBtnStattus: false});
    } 
 
  };
  handlePasswordValidtion = (password: string) => {
    if (!password.match(this.passwordRegEx)) {
      this.setState({
        validPass: false,
        isCheckPassword:false,
        enableBtn:true
      });
    } else {
      this.setState({
        validPass: true,
        isCheckPassword:false,
        enableBtn:true
      });
    }
    if(password === this.state.employeeData.empConfirmPass){
      this.setState({
        enableBtn:false
      })
    }
    if(password === this.state.formData.reTypePassword){
      this.setState({
        isCheckPassword:true
      })
    }
  };
  setRole = (type: string) => {
    if (type === "company") {
      this.setState({
        role: {
          company: true,
          employee: false,
        },
      });
      this.props.navigation.navigate("CompanySignup");
    } else {
      this.setState({
        role: {
          company: false,
          employee: true,
        },
      });
      this.props.navigation.navigate("EmployeeSignup");
    }
  };
  handleBackButtonClick = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
}
handleBackClick = () => {
  if(this.state.step===3){
    this.setState({ errorEmail: "",error:"" });
  }

  if(this.state.step==1){
    this.setState({   
      formData: {
        companyName: "",
        personName: "",
        dailCode: "",
        phoneNumber: "",
        taxIdentificationNumber: "",
        websiteLink: "",
        companyAddress: "",
        street: "",
        email: "",
        password: "",
        reTypePassword: "",
        countryName: "",
        stateNameCheck: "",
        cityNameCheck: "",
        zipCode: "",
        errorEmail: "",
        error: "",
        countryThreeDigit:"",
      },
    employeeData:{
      ...this.state.employeeData,
      dailNumber:""
    }
  })
  }
  this.setState({
    step: this.state.step - 1,
  })
}
handleBackClick2 = () => {
  if(this.state.step==1){
    this.setState({   
      employeeData: {
        companyId: "",
        contactNumber: "",
        dailNumber: "",
        employeeName: "",
        employeeEmail: "",
        empPassworrd: "",
        empConfirmPass: "",
        PhoneNumberValidationCheck:100,
      }
  })
  const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
  msg.addData(getName(MessageEnum.NavigationTargetMessage), "SelectRole");
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
  }
  this.setState({
    step: this.state.step - 1,
  })
  this.employeeValidation();
}

  handleContactNumberChange = (event: string, country: CountryCodeObj) => {
    this.setState({ phoneNumber: event, companyNameError: false }, () => {
    })
};

  handleTaxCheck =(value: string, name: string)=>{
    const regex = configJSON.taxRegex;
    if (regex.test(value)) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: value.trimStart()
        }
      }), () => this.checkValidation());
    }else {
      this.setState({companyAddressError: false,cityError: false, streetError:false,})
    }
  }

  handleStreetCheck = (value: string, name: string) => {
    if (value.length <= 42) {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value.trimStart()
          },
          streetError: false,
          companyAddressError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ streetError: true, companyAddressError: false ,secondBtnStatus:true});
    }
  };


  handleCityCheck = (value: string, name: string) => {
    const alphaRegex = configJSON.taxRegex;
    const trimmedValue = value.trim();

    if (alphaRegex.test(trimmedValue) && trimmedValue.length <= 42) {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: trimmedValue
          },
          cityError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ cityError: true });
    }
  };


  handlePersonCheck = (value: string, name: string) => {
    if (value.length <= 42) {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value.trimStart()
          },
          personNameError: false,
          companyNameError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ personNameError: true, companyNameError: false, btnStatus:true });
    }
  }


  handleCompanyAddressCheck = (value: string, name: string) => {
    if (value.length <= 100) {
      this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value.trimStart()
          },
          companyAddressError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({secondBtnStatus:true });
    }
  };

  handleCompanyNameBlur = () => {
    this.setState({
        companyNameError:false
      })
  }
  handleStreetNameBlur = () => {
    this.setState({
        streetError:false
      })
  }
  handlePersonNameBlur = () => {
    this.setState({
        personNameError:false
      })
  }

  handleCompanyNameCheck = (value: string, name: string) => {
    if (value.length <= 52) {
      if(name === "employeeName"){
        this.setState(
          (prevEmployeeData) => ({
            ...prevEmployeeData,
            employeeData: {
              ...prevEmployeeData.employeeData,
              [name]: value.trimStart(),
            },
            companyNameError:false
          }),
          () => {
            this.employeeValidation();
          }
        );
      }else {
        this.setState(
        prevState => ({
          formData: {
            ...prevState.formData,
            [name]: value.trimStart()
          },
          companyNameError: false
        }),
        () => this.checkValidation()
      );}
     
    } else {
      this.setState({ companyNameError: true });
    }
  }


  handleEmailCheck = (value: string, name: string) => {
    const emailRegex = configJSON.emailRegex;
   let email = this.state.formData
   email.email = value.trimStart()
    this.setState({formData : email},() => {
       this.checkValidation()
      if (!emailRegex.test(value)) {
        this.setState({ emailError: "Invalid email"})
      } else {
        this.setState({ emailError: "", companyNameError: false })
      }
    }) 
  }

  handleWebsiteCheck = (value: string, name: string,) => {
    const webSiteRegex = configJSON.webSiteRegex
    const urlCheck = value.trim();
    let urlTest = this.state.formData
    urlTest.websiteLink = value.trimStart()
    this.setState({ formData: urlTest },
      () => {
        if (webSiteRegex.test(urlCheck)) {
          this.checkValidation();
          this.setState({
            websiteLinkError: false,
          })
        } else {
          this.setState({
            websiteLinkError: true,
            
          }, () => {
            this.checkValidation();
          });
        }
      }
    );
  };
  handleCountryName = (value: string) => { 
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData, ["countryName" as string]: value,
      },
    }));

  };
  handleStateName = (value: string) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData, ["stateNameCheck" as string]: value,
      },
    }));
 

  };
  handleCityName = (value: string) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData, ["cityNameCheck" as string]: value,
      },
    }));
 
  };

  validateForm2 = () => {
    const { countryName, stateNameCheck, cityNameCheck, zipCode } = this.state.formData;
    if (!countryName ||   
      !stateNameCheck || 
      !cityNameCheck || 
      !zipCode
      ) {
      return true
    }else{
      return false
    }
  };

  handleEditDescription = (event: { target: { name: string, value: string } }) => {
    const { value } = event.target;
    if(event.target.name == "companyName"  ){
      this.setState({ error: "" })
    }
    else if(event.target.name == "email"  ){
      this.setState({ errorEmail: "" })
    }
    const descriptionValue = event.target.value;
    const name = event.target.name;

    if ( name === "taxIdentificationNumber") {
      this.handleTaxCheck(descriptionValue, name)
    }
    else if (name === "street") {
      this.handleStreetCheck(descriptionValue, name)
    }
    else if (name === "websiteLink") {
      this.handleWebsiteCheck(descriptionValue, name)
    }

    else if (name === "personName") {
     this.handlePersonCheck(descriptionValue, name)
    }
    else if (name === "companyAddress") {
     this.handleCompanyAddressCheck(descriptionValue, name)
    } else if (name === "companyName") {
     this.handleCompanyNameCheck(descriptionValue, name)
    }
    else if (name === "email") {
      this.handleEmailCheck(descriptionValue, name)
    }
    else if (name === "stateName") {
      this.handleStateName(value);
    }
    else if (name === "countryName") {
      this.handleCountryName(value);
    }  
    else if (name === "cityName") {
      this.handleCityName(value);
    }

    else {
      this.updateFormData(name, value);
    }
  };

  updateFormData = (name: string, value: string) => {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        const { zipCode } = this.state.formData;
        if (zipCode) {
          if (zipCode.length === 6) {
            this.getCityDetail(zipCode);
          } 
        }
      }
    );
  };
  

}
