import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Button,
    TextField, 
    MenuItem,
    Popover,
    Input,
    CircularProgress,
    Grid
} from "@material-ui/core";
import Pagination from '@mui/material/Pagination';
import { styled } from "@material-ui/styles";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomFormController, {
    Props,
    configJSON,

} from "./CustomFormController.web";
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web'
import KycWbSDK from '../../../components/src/KycWbSDK.web'
import Skeleton from '@material-ui/lab/Skeleton';
import DownloadPdfCustom from "../../../components/src/DownloadPdf"
const profileImg2 = require("../assets/blankProfile.jpeg");

// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    getStatusButton = (status: string) => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const rejectText = this.state.rejectedText;
        switch (status) {
            case 'GREEN':
                return (
                    <Button className="approvedBtn">
                        {configJSON.approvedText}
                    </Button>
                );
            case 'Pending':
                return (
                    <Button className="pendingBtn">
                        {configJSON.pendingText}
                    </Button>
                );
            case 'RED':
                return (
                    <>
                        <Button
                            className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
                            data-testId="rejectTestId"
                            onClick={this.handleClick}
                            endIcon={<ExpandMoreIcon />}
                        >
                            {rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                        </Button>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            data-testId="closeAnchorTestId"
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            PaperProps={{
                                style: {
                                    marginTop: "10px",
                                    backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                                    color: "black",

                                },
                            }}
                        >
                            <MenuItem className="needClaritiy" data-testId = "needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                            </MenuItem>
                        </Popover>
                    </>
                );
            default:
                return "No Status";
        }
    };

    renderSkeletonLoader=()=>{

        const Arr=[1,2,3,4,5,6,7,8];
        return(
          <TableBody >
      
            {
      
            Arr.map(i=>(
            <TableRow key={i}>
      {
        Arr.map(j=>(
          <TableCell key={j}>
          <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}  width={200} variant="text" />
          </TableCell>
        ))
      }
            </TableRow>
            ))
      }
      
          </TableBody>
        )
      }


      renderNoDataFoundEmployee=()=>{



        return(
            <>

              <div style={{height:'100%',display:'flex',alignItems:'center',background: '#242424'}}>
        <Grid container spacing={5} > 

    
        <Grid item md={12} lg={12} xs={12} sm={12} style={{display:'flex',justifyContent:'center',}} ><img src={require("../assets/image_.svg")} style={{width:'25%'}}  /> </Grid>

         <Grid item md={12} lg={12} sm={12} xs={12}  style={{display:'flex',justifyContent:'center',color:'white',fontWeight:'bold', fontSize:'20px'}}>No Data Found </Grid> 
              
         <Grid item md={12} sm={12} lg={12} xs={12} style={{display:'flex',justifyContent:'center',}}><Button data-test-id="navigationToKyc"
          style={{
      height: "44px",
      width: "181px",
      backgroundColor: "#00FF00", 
      color: "#242424",         
      textTransform: "none",      
      padding: "12px 20.5px", 
      cursor:'pointer',  
      borderRadius: 0,            
     
    }} variant="contained" onClick={this.kycNavigation}>{configJSON.verifyText}</Button></Grid>
         </Grid> 
      
         </div> 

            </>
        )

        
      }


      renderPaginationCell = () => {
        return (
            <TableBody>

                {
                    this.state.userData?.length > 0
                        ? this.state.userData.map((singleData) => {
                            return (
                                <TableRow key={singleData?.id}>
                                    <TableCell>
                                        <UserName>
                                            <img 
                                                src={singleData.attributes?.profile_picture || profileImg2 }
                                                alt="no image" 
                                                className="userImg" 
                                            />
                                            <Typography className="nameTxt">                                         
                                              {
                                                singleData.attributes.firstName ? singleData.attributes.firstName.length > 8
                                                    ? singleData.attributes.firstName.charAt(0).toUpperCase() + singleData.attributes.firstName.slice(1, 8).toLowerCase() + "..."
                                                    : singleData.attributes.firstName.charAt(0).toUpperCase() + singleData.attributes.firstName.slice(1).toLowerCase()
                                                    : ""
                                              }
                                            </Typography>
                                        </UserName>
                                    </TableCell>
                                    <TableCell className="dobTableTxt">
                                        {singleData?.attributes?.dob}
                                    </TableCell>
                                    <TableCell className="nationalityText">
                                        {singleData?.attributes?.nationality}
                                    </TableCell>
                                    <TableCell className="countryTableText">
                                        {singleData?.attributes?.country}
                                    </TableCell>
                                    <TableCell className="addressText">
                                        {singleData?.attributes?.addresses}
                                    </TableCell>
                                    <TableCell className="passportTextTable">
                                    <TableCell className="passportTextTable">
                                    {singleData.attributes?.passport_number  ? (singleData.attributes.passport_number.length > 8 ? `${singleData.attributes.passport_number.slice(0, 8)}...` : singleData.attributes.passport_number): ''}
                                     </TableCell>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            [`${singleData?.attributes?.passport}`].map((document: string, index: number) => (
                                                <TableDocWrapper key={document}>
                                                    <Box className="singleDoc">
                                                  
                                                     <DownloadPdfCustom downloadIcon={require("../assets/image_.png")} document={document} setDownloadPdfLoader={this.setDownloadPdfLoader1} />

                                                        <img src={require("../assets/image_texts.png")} />
                                                        <Typography className="documentNameText">
                                                            passport.pdf
                                                        </Typography>
                                                    </Box>
                                                </TableDocWrapper>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <StatusWrapper style={{ width: "130px" }}>                                          
                                                
                            {this.shouldShowDownloadIcon(singleData.attributes.status.review_result?.reviewAnswer) ? (
    <img src={require("../assets/image_.png")} className="downloadLargeBtn" />
  ) : null}
                                                 {this.getStatusButton(singleData.attributes.status.review_result?.reviewAnswer)}
                                        </StatusWrapper>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        :
                        <TableRow>
                            <TableCell colSpan={8} style={customStyles.noDataFoundDiv}>
                                <Box style={customStyles.noDataFoundBox}>
                                    <Typography style={customStyles.noDataFoundText}>
                                        No Data Found
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                }
            </TableBody>
        );
    };
    

    renderEmployeForm = () => {
        return (
            <div style={{height:"100%"}}>
            <MainContent style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
                <PageTop className="pageMain">
                    <Typography className="headerText" >{configJSON.userInformation}</Typography>
                    <Box className="searchContainer">
                        <Box className="searchInputContainer">
                            <Input
                                className="searchInputfield"
                                fullWidth
                                placeholder="Search"
                                disableUnderline
                                name="cardNumber"
                                data-testId="cardNumberTestId"
                                onChange={this.handleChangeSearchFun}
                                value={this.state.searchKey}
                                startAdornment={
                                    <SearchIcon className="searchIcon" />
                                }
                                endAdornment={
                                    this.state.searchValue &&
                                    <ClearIcon  onClick={this.clearData} className="crossIcon" />
                                }
                            />



                        </Box>
                        <Button data-test-id="navigationToKyc" className="searchBtn" variant="contained" onClick={this.kycNavigation}>{configJSON.verifyText}</Button>
                      {false && <Button  data-testId="navigationToKyc1" className="searchBtn" variant="contained" onClick={this.CheckSDK}>Verify Passport</Button>}
                    </Box>     
                </PageTop>
                <StyledTableContainer style={{ overflowX: this.state.userDataLoading ? 'hidden' : 'auto'}} className="StyledTableContainer">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                {

                                    this.state.tableHeaderData.filter((hData: { [key: string]: string }) => {
                                        if (this.state.searchValue) {
                                            return hData.name === "Name" || hData.name === "Date of Birth" || hData.name === "Nationality" || hData.name === "Status"
                                        }
                                        return hData.name.includes("");
                                    }).map((hData: { [key: string]: string }) => <TableCell className={hData.className} key={hData.name}>{hData.name}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>                   
                        {
                        
                            !this.state.userDataLoading ?
                                this.renderPaginationCell()
                                :       
                                        this.renderSkeletonLoader()
                        }
                    </StyledTable>
                
                </StyledTableContainer>
                {
                    !this.state.userDataLoading &&
                
                    <PagginationBox data-testId="paginationBox">
                    <Pagination data-testId="paginationTestId" count={Math.ceil(this.state.customUserListLength  / this.state.customperPage) || 0} variant="outlined" shape="rounded" onChange={this.handlePageChange} page={this.state.customCurrentPage} />
                </PagginationBox>

              }     
             
            </MainContent>
            </div>
        )
    }
  // Customizable Area End

  render() {    
    return (
      // Customizable Area Start
      <>
      {
        this.state.role === "company" ? <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="CustomFormUserList"
        >
        { this.state.noDataFound ?  this.renderNoDataFoundEmployee() : this.renderEmployeForm()}
      </Headers> 
      :

      <EmployeHeader
      sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="CustomFormUserList"
      >
        { this.state.noDataFound ?  this.renderNoDataFoundEmployee() : this.renderEmployeForm()}
      </EmployeHeader>
      }
    </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledTableContainer = styled(TableContainer)({
    width: "100%",   
    height: "auto",
    gap: "24px",
    opacity: "0px",

    "& .userImg": {
        width: "48px",
        height: "48px",
        borderRadius: "48px"
    },
    "@media (max-width: 768px)": {
            marginTop: "110px"
    },   
});

const StyledTable = styled(Table)({
    backgroundColor: "#18191b",
    "& .MuiTableCell-root": {
        borderBottom: "1px solid #272B30",
    },
    "& .MuiTableCell-head": {
        color: "#fff",
        width: "max-content",
    },
    "& .MuiTableCell-body": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600
    },
    "& .nameTxt": {
        color: "rgba(252, 252, 252, 1)",
        fontWeight: 700,
        fontFamily: 'Lato',
        height: "24px",
        width: "114px",
        fontSize: "15px"
    },
    "& .dobTableTxt": {
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        height: "81px",
        width: "24px"
    },
    "& .countryTableText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        width: "84px",
        height: "24px",
        fontFamily: 'Lato',
        whiteSpace:"nowrap" 
    },
    "& .passportTextTable": {
        width: "81px",
        height: "24px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 600,
    },
    "& .nationalityText": {
        width: "80px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px"
    },
    "& .addressText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "249px",
        height: "48px"
    },
    "& .lato": {
        fontFamily: 'Lato'
    },
    "& .dobTableCell": {
        width: "78px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600,
        whiteSpace:"nowrap"     

    },
    "& .nameTableCell":{
        width: "36px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .nationalityTableCell":{
        color: 'rgba(211, 211, 211, 1)',
        width: "68px",
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .countryTableCell":{
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        width: "50px",
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .addressTableCell":{
        width: "50px",
        color: 'rgba(211, 211, 211, 1)',
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .passportTableCell":{
        width: "106px",
        fontFamily: 'Lato',
        fontWeight: 600,
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentTableCell":{
        gap: '0px',
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .statusTableCell":{
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        fontWeight: 600,
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentNameText": {
        fontFamily: 'Lato',
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)"
    }
})

const UserName = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px"
})

const TableDocWrapper = styled(Box)({
    "& .singleDoc": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    }
})

const StatusWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .approvedBtn": {
        backgroundColor: '#41d613',
        color: 'rgba(36, 36, 36, 1)',
        width: "76px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .pendingBtn": {
        backgroundColor: 'yellow',
        color: 'rgba(36, 36, 36, 1)',
        width: "80px",
        height:"28px",
        extTransform: "none",
        marginLeft: "33px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .rejectedBtn": {
        backgroundColor: 'red',
        color: 'rgba(36, 36, 36, 1)',
        width: "98px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .needClarificationBtn": {
        backgroundColor: "#FF7A01",
        color: "rgba(36, 36, 36, 1)",
        width: "200px",
        height:"36px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight:"24px"
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    },
    "& .downloadLargeBtn": {
        width: "24px",
        height: "24px"
    }
})

const PageTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    paddingBottom: "20px",
    
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px",
        
        "& .searchInputContainer": {
            position: "relative",
            
            "& .searchStatusContainer": {
                position: "absolute",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                backgroundColor: "rgba(36, 36, 36, 1)",
                width: "100%",
                listStyle: "none",
                margin: 0,
                height: "10px",

                "& .searchStatus": {
                    padding: "12px",
                    fontSize: "8px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    fontFamily: "lato",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },

                "& .searchApprovedStatus": {
                    border: "1px solid rgba(0, 255, 0, 1)",
                    color: "rgba(0, 255, 0, 1)",
                },
                "& .searchPendingStatus": {
                    border: "1px solid rgba(251, 212, 0, 1)",
                    color: "rgba(251, 212, 0, 1)",
                },
                "& .searchRejectedStatus": {
                    border: "1px solid rgba(255, 25, 56, 1)",
                    color: "rgba(255, 25, 56, 1)",
                },
                "& .searchClarificationStatus": {
                    border: "1px solid rgba(255, 122, 1, 1)",
                    color: "rgba(255, 122, 1, 1)",
                },
            },
        },
    },

    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "320px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",

        "& .MuiInputBase-input": {
            width: "272px",
            height: "24px",
            fontFamily: "Lato",
        },
    },

    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },

    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px",
        cursor: "pointer",
    },

    "& .crossIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "18.05px",
        height: "18.06px",
        cursor: "pointer",
    },

    "& .searchBtn": {
        height: "44px",
        width: "181px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
    },

    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },

    "@media (max-width: 1024px)": {
        "& .searchInputfield": {
            width: "250px",
        },
        "& .pageMain": {
            width: "100%",
            padding: "0 20px",
        },
        "& .searchBtn": {
            width: "150px",
        },
    },
    "@media (max-width: 768px)": {
        flexDirection: "column",
        "& .pageMain": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& .searchContainer": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
        "& .searchInputfield": {
            width: "100%",
        },
        "& .searchBtn": {
            width: "100%",
        },
    },

    "@media (max-width: 480px)": {
        "& .searchInputfield": {
            height: "36px",
            fontSize: "14px",
        },
        "& .searchBtn": {
            width: "100%",
            fontSize: "14px",
        },
    },
});

const MainContent = styled(Box)({  
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "rgba(26, 29, 31, 1)",
    fontFamily: 'Lato',
    position: "relative",   
    "& .paginationButtons":{
        display: "flex",
        justifyContent: "center",
        marginTop:"10px",
        "& .MuiPagination-ul li":{
            backgroundColor: "grey"
        }
    },
    "& .loaderWrapper":{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#fff"
    },
    "& .headerText": {
        display: "flex",
        color: "rgba(252, 252, 252, 1)",
        fontSize: "40px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "100%",
        maxWidth: "450px",
        height: "auto",
        marginBottom: "20px",
        lineHeight: "1.2",
        textAlign: "left",
    },
    "@media (max-width: 768px)": {
        "& .headerText": {
            fontSize: "30px",
            maxWidth: "100%",
            textAlign: "center",
        },

    },
    "@media (max-width: 480px)": {
        "& .headerText": {
            fontSize: "24px",
            textAlign: "center",
            marginBottom: "15px",
        },
    },
    
})
const customStyles = {
    noDataFoundDiv: {
      textAlign: 'center'
    } as React.CSSProperties,
    noDataFoundBox: {
      display: "flex", 
      justifyContent: "center", 
      margin: "30px 0px",
    },
    noDataFoundText: {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Open Sans",
      color: "#390689"
    },
  }
export const PagginationBox = styled(Box)({
    marginLeft: "auto",
    marginTop: "20px",
    marginBottom:"35px",
    width: "max-content",
    "& .MuiSvgIcon-root": {
        fill: "grey"
    },
    "& .MuiPaginationItem-page": {
        backgroundColor: "unset",
        color: "rgba(0, 255, 0, 1)",
        borderBottom: "unset !important",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        border: "1px solid grey !important"
    },
    "& .MuiPaginationItem-page.Mui-selected::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul .MuiButtonBase-root": {
        position: "relative",
        borderRadius: "50%",
        border: "none"
    },

    "& .MuiPagination-ul .MuiButtonBase-root::before": {
        content: "",
    },
    "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .showingTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        color: "#212121"
    },
    "@media (max-width: 768px)": {
        justifyContent: "center",
        margin: "20px auto",
    },
});
// Customizable Area End
