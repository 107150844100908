import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface AccountSubscriptionAPi {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      price: string;
      description: string;
      store_credit_id: number;
      subscriptions_plan_benefit_id: [];
      image_link: string;  
      type:string;    
    };
  }[];
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
}

export interface S {
    // Customizable Area Start
  cardNumber: string;
  cardholderName: string;
  expiryDate: string;
  cvv: string;
  totalAmount: number;
  sideBarClose: boolean;
  accountSubscription: {
    name: string,
    price: string,
    description: string,
    storeCreditId: number,    
    imageLink: string,
    type:string,
    subscriptionsPlanBenefitId: [],
   },
   anchorEl: HTMLButtonElement | null;
   isDialogOpen:boolean;
   successDialogOpen:boolean;
   failDialogOpen:boolean;
   // Customizable Area End
}

export interface SS {
  id: any;
}

export const configJSON = require("./config");

export default class RenewSubscriptionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  accountSubscriptionApiCallId : string = "";
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handlePayment = this.handlePayment.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
         // Customizable Area Start
      cardNumber: '',
      cardholderName: '',
      expiryDate: '',
      cvv: '',
      totalAmount: 40.00,
      sideBarClose: false,
      accountSubscription: {
        name: "",
        price: "",
        description: "",
        storeCreditId: 0,               
        imageLink: "",
        subscriptionsPlanBenefitId:[],
        type:""
        
   },
   anchorEl:  null,
        isDialogOpen:false,
        successDialogOpen:false,
        failDialogOpen:false
   
   // Customizable Area End
    };
  }

  async componentDidMount() {  
    super.componentDidMount();   
    this.getAccountSubscription();
  }  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (apiRequestCallId === this.accountSubscriptionApiCallId) {   
         this.accountSubscriptionAPIResponse(responseJson) 
      }

    }
    // Customizable Area End
  }

  handleCancel = () => {
    this.setState({ anchorEl: null,isDialogOpen:false,failDialogOpen:false,successDialogOpen:false});
  };
  handleSuccessDialogBox = () =>{
    this.setState({successDialogOpen:true,failDialogOpen:false });
  }
  handleFailDialogBox = () =>{
    this.setState({ failDialogOpen:true ,isDialogOpen:false});
  }
  handleFailureDialogBox = () =>{
    this.setState({successDialogOpen:false,failDialogOpen:false });
  }
  handleSliderButton = () => {
    this.setState({ sideBarClose: !this.state.sideBarClose });
  }

  handlePayment = async () => {

  }

  handleInputChange = (name: string, value: string) => {
    this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
  }

  navigateToScreen = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
    this.send(message);
  };

   
  accountSubscriptionAPIResponse = (responseJson: AccountSubscriptionAPi) => {
    const attributes = responseJson.data[0].attributes;
    this.setState({
      accountSubscription: {
        ...this.state.accountSubscription,
        name: attributes.name,
        price: attributes.price,
        description: attributes.description,
        storeCreditId: attributes.store_credit_id,        
        imageLink: attributes.image_link,
        subscriptionsPlanBenefitId:attributes.subscriptions_plan_benefit_id,
        type:responseJson.data[0].type
      },
    });
  };

  getAccountSubscription = () => {  
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSubscriptionEndPoint2
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}