import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Link, Typography, styled } from '@material-ui/core';
import {blurImage,mainLogo, logo, mainImage,ArrowIcon } from "./assets";
import OtpInput from 'react-otp-input'
import EmailAccountLoginController, { Props } from "./EmailAccountLoginController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoadImageComponentEnterOtp from "../../../components/src/LazyLoadImageComponent"
// Customizable Area End

export default class EnterOtp extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const minutes = Math.floor(this.state.countTimer / 60);
        const seconds = this.state.countTimer % 60;

        return (
            <OtpStyle>
                <Box>
                    <Grid item xl={12} xs={12} lg={12} sm={12}>
                        <Box 
                         style={{
                backgroundColor: "#242424",
                width: "100%",
                borderBottom: "1px solid #505050",
                display:'flex',
                justifyContent:'flex-end',
              }}
              >
              <LazyLoadImage
               src={mainLogo}
               style={{ 
                   marginLeft: "auto",
                   display: "flex",
                   paddingRight: "2%",
              marginRight:'20px' }}
              wrapperClassName="lazy-load-wrapper-logo"
              placeholderSrc={logo}
               effect="blur"
               alt="logo"
                />

                        </Box>
                    </Grid>
                </Box>
                <Box>
                 <Grid container>
                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12} style={{ backgroundColor: '#242424' }} >
                            <Box className="main-container">
                                <Box style={{ width: '55%', margin: 'auto' }}>
                              <img src={ArrowIcon} alt="Arrow Icon" style={{marginBottom: '15px' , cursor: "pointer"}} onClick={this.handleBackButtonClick} data-test-id="btn-back"/>
                                    <Box style={{ color: 'white', paddingBottom: '4%' }}>
                                        <Typography className="createAccount">
                                            Check your Email
                                        </Typography>
                                        <Typography className="subheading" >
                                         We sent a verification link to {this.state.PersonEmail}
                                        </Typography>
                                    </Box>
                                    <Box style={{ marginTop: '2%' }}>
                                        <OtpInput
                                            containerStyle={{ display: 'flex', alignItems: 'center', width: '100%' }}
                                            onChange={this.getOtp}
                                            numInputs={4}
                                            value={this.state.otpData.otp}
                                            renderSeparator={<span style={{ width: "8px" }}></span>}
                                            renderInput={(props) => (
                                                <input
                                                  {...props}
                                                  name="otptest"
                                                  onKeyDown={this.handleKeyDown}
                                                />
                                              )}
                                            shouldAutoFocus={true}
                                            inputStyle="inputStyle"
                                            data-test-id="renderInput"
                                        />
                                    </Box>
                                    <Button 
                                        data-test-id="customButtonTest"
                                        disabled={this.state.otpData.otp.length !== 4} 
                                        style={ this.state.otpData.otp.length !== 4? { backgroundColor: "#7C7C7C" }: { backgroundColor: "#00FF00" }} 
                                        onClick={this.otpLogin} 
                                        className="customButton">                                       
                                 {this.state.Loader ? <CircularProgress thickness={4} size={30} /> : " Continue"}
                                    </Button>
                                        <br/>
                                    {(this.state.countTriger) ? 
                                    <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'left' }}>
                                        <Typography className="errorText" >Please try again after {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</Typography>
                                    </Box>:
                                    <>
                                    <Box style={!this.state.showTimer  ? {  visibility: "hidden" } : { color: '#00FF00'}} >
                                        <Typography style={{ color: '#00FF00'}} className="TimeRemainingCSS">Time remaining {this.state.timer}</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Typography style={{ color: '#D3D3D3' }} className="receiveTextCss">Didn't receive the email?</Typography>
                                        <div style={{ color: '#00FF00', paddingLeft: '1%',pointerEvents: this.state.disableLink? 'none' : 'auto'  }}>
                                            <Typography style={this.state.disableLink? { color: "#7C7C7C" }: { color: "#00FF00" , cursor:"pointer"}} onClick={this.restartTimer} className="ResetTestCSS">Click to resend</Typography>
                                        </div>
                                    </Box>
                                    </>
                                    }
                                    <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                                        <Typography style={{ color: '#D3D3D3' }} className='BackBtnCss'>Back to</Typography>
                                        <div style={{ color: '#00FF00', paddingLeft: '1%' }}>
                                            <Typography onClick={this.naviagateLogin} className='LogInTextCSS' data-test-id="NagivateLogin">Log In</Typography>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>                     
                        <LazyLoadImageComponentEnterOtp mainImage={mainImage} blurImage={blurImage} />
<style>
  {
    `
    .lazy-load-wrapper-logo{
        width: "100%",
      backgroundColor: "#242424",
      borderBottom: "1px solid #505050",
    }
    `
}
</style>                         
                        </Grid>
                    </Grid>
                </Box>
            </OtpStyle>
        );
    }
}
const OtpStyle = styled(Box)({
    '& .MuiInput-underline::after': {
        borderBottom: 'none'
    },
    '& .phoneCodeStyle': {
        position: 'absolute',
        color: 'white',
        marginTop: '1%'
    },
    '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    "& .cb1": {
        borderRadius: '50%',
        verticalAlign: 'middle',
        border: '1px solid pink',
        appearance: 'none',
        outline: 'none',
    },
    '& .PrivateTabIndicator-colorPrimary-10': {
        backgroundColor: 'none'
    },
    '& .customButton': {
        width: '346px',
        height: '56px',
        textTransform: 'none',
        marginTop: '4%',
        backgroundColor: '#00FF00',
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
    },
    '& .MuiIconButton-edgeEnd': {
        marginRight: '0px'
    },
    '& .inputCheck': {
        height: '20px',
        width: '20px',
        cursor: 'pointer',
        backgroundColor: 'none'
    },
    '& .css-1laao21-allyText': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: ' none ! important',
    },
    '& .MuiInputBase-input': {
        color: 'white'
    },
    '& .css-1pahdxg-control': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    '& .ResetTestCSS': {
        lineHeight: '24px',
        fontFamily: 'Lato',
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: 400,
    },
    '& .main-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    '& .inputContainer': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '5%'
    },
    '& .createAccount': {
        fontSize: '30px',
        fontWeight: 700,
        color: '#D3D3D3',
        fontFamily: 'Lato',  
        lineHeight: '40px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
    },
    '& .subheading': {   
        color: '#D3D3D3',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'left',
    },
    '& .receiveTextCss': {
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: 'Lato',
        textAlign: 'left',
        fontWeight: 400,
    },
    '& .css-yk16xz-control ': {
        width: '100%',
        height: '56px',
        backgroundColor: '#242424',
        color: '#A7A7A7',
        border: '1px solid #FFFFFF',
        paddingLeft: '2%'
    },
    '& .lableName': {
        fontSize: '16px',
        fontWeight: 700,
        color: '#D3D3D3'
    },
    '& .inputStyle': {
        border: '2px solid #FFFFFF',
        borderRadius: '8px',
        height: '80px',
        width: '5rem !important',
        color: '#D3D3D3',
        fontSize: '48px',
        background: 'none',
        boxShadow: '0px 1px 2px 0px #1018280D',
        opacity: '0.6'
    },
    '& .MuiSvgIcon-root':{color: "white"},
    '& .inputStyle:focus': {
        outline: 'none',
        opacity: '1.0'
    },
    '& .errorText':{
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "left",
        color: "#FF1938",
        marginTop: "24px",
        marginBottom: "24px",
    },
    '& .TimeRemainingCSS':{
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'Lato',
        textAlign: 'left',
    },
    '& .LogInTextCSS': {
        fontFamily: 'Lato',
        cursor:"pointer",
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
    },
    '& .BackBtnCss': {        
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        fontFamily: 'Lato',
    },
    "& .loading-container1": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
      "& .spinner2": {
        width: "80px",
        height: "80px",
        border: "6px solid rgba(0, 0, 0, 0.1)",
        borderRadius: "50%",
        borderTopColor: "#000",
        animation: "spin 1s ease-in-out infinite",
      },
      "& .loadingText": {
        marginTop: "10px",
        fontSize: "20px",
        fontWeight: "bold",
      },
    
})
