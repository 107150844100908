import React from "react";

// Customizable Area Start
import { Box, Grid, Typography, styled } from "@material-ui/core";
import {blurImage,mainLogo,logo} from "./assets"
import LazyLoadImageComponentSelectRole from "../../../components/src/LazyLoadImageComponent"
import { LazyLoadImage } from "react-lazy-load-image-component";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import { forwordArrow, laptopImg, mainImage, userImg,arrowIconBack,LaptopIMGBlack,EmployeeIMGBlack,RightBlackArrow } from "./assets";

export default class SelectRole extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <SelectRoleStyling>
          <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box
              style={{
                display:'flex',
                backgroundColor: "#242424",
                width: "100%",
                justifyContent:'flex-end',
                borderBottom: "1px solid #505050",
              }}
            >


                <LazyLoadImage
               wrapperClassName="lazy-load-wrapper-logo"
               src={mainLogo}
               effect="blur"
               style={{ display: "flex",
               paddingRight: "2%",
               marginLeft: "auto",
              marginRight:'20px' }}
              alt="logo"
              placeholderSrc={logo}
                />

            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={0}>
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={12}
              xs={12}
              style={{ backgroundColor: "#242424" }}
            >
              <Box className="main-container">
                <Box style={{ width: "55%", margin: "auto" }}>
                  <Box style={{ color: "white" }}>
                  <img
                      src={arrowIconBack}
                      alt="Arrow Icon"
                      style={{ cursor: "pointer", marginBottom: "2px" }}
                      onClick={this.handleBackButtonClick}
                      data-test-id="btnback"
                    />
                    <Typography className="createAccount">
                      Select Role
                    </Typography>                    
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1vw",
                      marginTop: "8%",
                      marginBottom:"20px"
                    }}
                  >
                    <Box
                      className="cardContainer"
                      data-test-id="companyCard"
                      onClick={() => this.setRole("company")}
                      onMouseEnter={() => this.setState({ isHovered: false })}
                      onMouseLeave={() => this.setState({ isHovered: true })}
                    >
                      <Box>
                        <img alt="company" className="imgSelect" src={this.state.isHovered ? laptopImg : LaptopIMGBlack} /> 
                      </Box>        
                      <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}><Box style={{paddingLeft:"8px"}}>
                          Company
                        </Box>
                      <img style={{paddingRight:"32px"}} alt="company" src={this.state.isHovered ? forwordArrow : RightBlackArrow} />
                      </Box>
                    </Box>
                    <Box
                      className="cardContainer"
                      data-test-id="employeeCard"
                      onClick={() => this.setRole("employee")}
                      onMouseEnter={() => this.setState({ isHoveredEmp: false })}
                      onMouseLeave={() => this.setState({ isHoveredEmp: true })}
                    >
                      <Box >                                      
                        <img className="imgSelect" alt="company" src={this.state.isHoveredEmp ? userImg : EmployeeIMGBlack} />                       
                      </Box>                      
                      <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",width: "100%"}}> 
                      <Box style={{paddingLeft:"8px"}}>Employee </Box>                
                       <img style={{paddingRight:"32px"}} alt="company" src={this.state.isHoveredEmp ? forwordArrow : RightBlackArrow} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>
                            
             
                 <LazyLoadImageComponentSelectRole mainImage={mainImage} blurImage={blurImage} />
                 <style>
  {
    `
    .lazy-load-wrapper-logo{
      backgroundColor: "#242424",
      width: "100%",
      borderBottom: "1px solid #505050",
    }
    `
}
</style>  
          
            </Grid>
          </Grid>
        </Box>
      </SelectRoleStyling>
    );
  }
}
const SelectRoleStyling = styled(Box)({
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  "& .createAccount": {
    fontSize: "30px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: "Lato",
    lineHeight: "40px",
  },
  "& .subheading": {
    fontSize: "14px",
    color: "#D3D3D3",
    fontFamily: "Lato",
    fontWeight: 500,
    lineHeight: "22px",
  },
  "& .customButton": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "4%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
  },
  "& .cardContainer": {
    backgroundColor: "#1A1D1F",
    display: "flex",
    height: "88px",
    alignItems: "center",
    cursor: "pointer",
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21.6px",
    padding:"0px 10px"
  },
  "& .cardContainer:hover": {
    backgroundColor: "00FF00",
    color: "#242424 !important",
  },
  "& .cardLabel": {
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21.6px",
  },
  "& .cardLabel:hover": {
    color: "#242424",
  },
  "& .imgSelect":{
    padding:"0px 16px",
  },
  "& .loading-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  "& .spinner": {
    width: "80px",
    height: "80px",
    border: "6px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "50%",
    borderTopColor: "#000",
    animation: "spin 1s ease-in-out infinite",
  },
  "& .loading-text": {
    marginTop: "10px",
    fontSize: "20px",
    fontWeight: "bold",
  }
});
