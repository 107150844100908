import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  TableContainer,
  MenuItem,
  Input,
  IconButton
} from "@mui/material";
import Headers from '../../../components/src/Headers.web';
import { styled } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import {  ArrowBack } from '@mui/icons-material';
import { configJSON } from "./InviteEmployeeController.web";
import Pagination from '@mui/material/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
const profileImg2 = require("../assets/blankProfile.jpeg");
import ClearIcon from '@material-ui/icons/Clear';
import DownloadPdf from "../../../components/src/DownloadPdf";
import {  pdfIcon, downloadIcon } from "./assets";
// Customizable Area End
import InviteEmployeeController, {
  Props
} from "./InviteEmployeeController.web";

export default class ViewKycChecklist extends InviteEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  getStatusButton = (status: string) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const rejectText = this.state.rejectedText;
    switch (status) {
        case 'GREEN':
            return (
                <Button className="approvedBtn">
                    {configJSON.approvedText}
                </Button>
            );
        case 'Pending':
            return (
                <Button className="pendingBtn">
                    {configJSON.pendingText}
                </Button>
            );
        case 'RED':
            return (
                <>
                    <Button
                        className={rejectText ? "rejectedBtn" : "needClarificationBtn"}
                        data-testId="rejectTestId"
                        onClick={this.handleClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        {rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        data-testId="closeAnchorTestId"
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style: {
                                marginTop: "10px",
                                backgroundColor: !this.state.rejectedText ? "red" : "#FF7A01",
                                color: "black",

                            },
                        }}
                    >
                        <MenuItem className="needClaritiy" data-testId = "needClarificationTestId" onClick={this.handleNeedClarification}> {!rejectText ? configJSON.rejectedText : configJSON.clearificationText}
                        </MenuItem>
                    </Popover>
                </>
            );
        default:
            return null;
    }
  };

  renderSkeletonLoader=()=>{

      const Arr=[1,2,3,4,5];
      return(
        <TableBody>
    
          {
    
          Arr.map(i=>(
          <TableRow>
    {
      Arr.map(i=>(
        <TableCell>
        <Skeleton style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}  width={200} variant="text" />
        </TableCell>
      ))
    }
          </TableRow>
          ))
    }
    
        </TableBody>
      )
    }

    renderPaginationCell = () => {
      return (
          <TableBody>
              {
                  this.state.userData?.length > 0
                      ? this.state.userData.map((singleData) => {
                          return (
                              <TableRow key={singleData?.id}>
                                  <TableCell>
                                      <UserName>
                                          <img 
                                              src={singleData.attributes?.profile_picture || profileImg2 }
                                              alt="no image" 
                                              className="userImg" 
                                          />
                                          <Typography className="nameTxt">
                                          {singleData.attributes?.firstName 
                                           ? singleData.attributes.firstName.charAt(0).toUpperCase() + singleData.attributes.firstName.slice(1).toLowerCase() 
                                           : ""}
                                          </Typography>
                                      </UserName>
                                  </TableCell>
                                  <TableCell className="dobTableTxt">
                                      {singleData?.attributes?.dob}
                                  </TableCell>
                                  <TableCell className="nationalityText">
                                      {singleData?.attributes?.nationality}
                                  </TableCell>
                                  <TableCell className="countryTableText">
                                      {singleData?.attributes?.country}
                                  </TableCell>
                                  <TableCell className="addressText">
                                      {singleData?.attributes?.addresses}
                                  </TableCell>
                                  <TableCell className="passportTextTable">
                                      {singleData?.attributes?.passport_number}
                                  </TableCell>
                                  <TableCell>
                                      {
                                          [`${singleData?.attributes?.passport}`].map((document: string) => (
                                            <TableDocWrapper key={document}>
                                                <Box className="singleDoc">
                                               
                                                  
                                                    <DownloadPdf downloadIcon={downloadIcon} document={document} setDownloadPdfLoader={this.setDownloadPdfLoader} />
                                                    <img src={pdfIcon} />
                                                    <Typography className="documentNameText">{configJSON.passportPdf}</Typography>
                                                </Box>
                                            </TableDocWrapper>
                                        ))
                                      }
                                  </TableCell>
                                  <TableCell>
                                      <StatusWrapper style={{ width: "130px" }}>                                          
                                              <img src={require("../assets/image_.png")}/>
                                               {this.getStatusButton(singleData?.attributes?.status?.review_result?.reviewAnswer)}
                                      </StatusWrapper>
                                  </TableCell>
                              </TableRow>
                          )
                      })
                      :
                      <TableRow>
                          <TableCell colSpan={8} style={customStyles.noDataFoundDiv}>
                              <Box style={customStyles.noDataFoundBox}>
                                  <Typography style={customStyles.noDataFoundText}>
                                      No Data Found
                                  </Typography>
                              </Box>
                          </TableCell>
                      </TableRow>
              }
          </TableBody>
      );
  };

  renderDashboardForm = () => {
    return (
      <Wrapper>
         <Box style={{ padding: "0px", display:"flex" }}>
            <IconButton edge="start">
              <ArrowBack data-testId="backButtonTest_view" onClick={() => this.goToScreen("PeopleManagement")} style={{width: "40px",height: "40px", color: "white"}}/>
            </IconButton>
            <Typography className="home">{configJSON.homeText}</Typography>
        </Box>
        <Box className="contentBox">
          <Typography className="tableHeader">
            {configJSON.userInformation}
          </Typography>
          <PageTop className="pageMain">
            
                      <Box className="searchContainer">
                          <Box className="searchInputContainer">
                              <Input
                                  className="searchInputfield"
                                  fullWidth
                                  placeholder="Search"
                                  disableUnderline
                                  name="cardNumber"
                                  data-testId="cardNumberTestId"
                                  onChange={this.searchHandleChange}
                                  value={this.state.searchValue}
                                  startAdornment={
                                      <SearchIcon className="searchIcon" />
                                  }
                                  endAdornment={
                                      this.state.searchValue &&
                                      <ClearIcon  onClick={this.clearData} className="crossIcon" />
                                  }
                              />
                              {
                                  this.state.searchValue &&
                                  <Box className="searchStatusContainer" component={"ul"}>
                                      {
                                          this.state.searchStatusData.map((status: { [key: string]: string }) => <Box component={"li"} className={`${status.className} searchStatus`}>
                                              {status.status}
                                          </Box>)
                                      }
                                  </Box>
                              }
                          </Box>
                      </Box>     
          </PageTop>
        </Box>
        <StyledTableContainer className="StyledTableContainer">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                {

                                    this.state.tableHeaderData.filter((hData: { [key: string]: string }) => {
                                        if (this.state.searchValue) {
                                            return hData.name === "Name" || hData.name === "Date of Birth" || hData.name === "Nationality" || hData.name === "Status"
                                        }
                                        return hData.name.includes("");
                                    }).map((hData: { [key: string]: string }) => <TableCell className={hData.className} key={hData.name}>{hData.name}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>                   
                        {
                        
                        !this.state.userDataLoading ?
                            this.renderPaginationCell()
                            :
                            <Box className="loaderWrapper" data-testId="dataNotFound">
                              
                                {
                                    this.renderSkeletonLoader()
                                }
                            </Box>
                    }
                    </StyledTable>
                
        </StyledTableContainer>
        {
            this.handleLoader() &&
            <PagginationBox>
                 <Pagination data-testId="paginationTestId" count={Math.ceil((this.state.viewKycTotalCount || 0)/ 10)} variant="outlined" shape="rounded" onChange={this.handlePageChange} page={this.state.viewKycCurrentPage} />
            </PagginationBox>
        }    
      </Wrapper>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
      {
        <Headers sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        isRouteData="PeopleManagement"
        >
          {this.renderDashboardForm()}
      </Headers> 
      }
    </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled(Box)({
  background: "#242424",
  padding: "12px 24px",
  height: "100vh",
  boxSizing: "border-box",
  "& .home": {
    fontWeight: 600,
    color: "rgba(252,252,252,1)",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-2%",
    fontFamily: "Lato",
  },
  "& .headerBox": {
    marginTop: "18px",
    color: "rgba(252,252,252,1)",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .itemBox": {
    background: "rgba(26,29,31,1)",
    padding: "24px 19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap:"15px"
  },
  "& .itemBoxText": {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Lato",
    latterSpacing: "-1%",
  },
  "& .lastBox": {
    width: "240px",
  },
  "& .loadMoreBtnBox": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px"
  },
  "& .loadMoreBtn": {
    textTransform: "none",
    color: "white",
    height: "48px",
    border: "2px solid rgba(39, 43, 48, 1)",
    fontSize: "15px",
    fontWeight: 700,
    borderRadius: "12px",
    fontFamily: 'Lato',
  },
  "& .itemBoxCount": {
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: 'Lato',
  },
  "& .itemBoxIcon": {},
  "& .contentBox": {
    color: "rgba(252,252,252,1)",
    padding: "24px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableHeader": {
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    latterSpacing: "-2%",
  },
  dialogFirstInputField: {
    width: "100%", borderRadius: '8px',
    fontWeight: 600,
    border: '0.5px solid #F1F5F9',
    background: '#F1F5F9',
  },
  "& .datePicker": {
    border: "2px solid #808080",
    width: "180px",
    height: "44px",
    padding: "10px 18px",
    color: "#808080",
  },
  "& .calnderStyle": {
    border: "2px solid rgba(80, 80, 80, 1)",
    width: "180px",
    height: "42px",
    color: "rgba(80, 80, 80, 1)",
    marginTop: "0px",
    paddingBottom: "10px"
  },
  "& .placeholder-white::placeholder": {
    color: "white",
    "& .MuiInputBase-input":
    {
      paddingLeft: "36px"
    }
  },
});

const customStyles = {
  noDataFoundDiv: {
    textAlign: 'center'
  } as React.CSSProperties,
  noDataFoundBox: {
    display: "flex",
    justifyContent: "center",
    margin: "30px 0px",
  },
  noDataFoundText: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Open Sans",
    color: "#390689"
  },
}

const StyledTableContainer = styled(TableContainer)({
  width: "Fixed (1,264px)px",
  height: "Fixed (41px)px",
  gap: "24px",
  opacity: "0px",

  "& .userImg": {
    width: "48px",
    height: "48px",
    borderRadius: "48px"
  }
});

const TableDocWrapper = styled(Box)({
  "& .singleDoc": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px",
    cursor:"pointer"
  }
});

const StyledTable = styled(Table)({
  backgroundColor: "#18191b",
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #272B30",
  },
  "& .MuiTableCell-head": {
    color: "#fff",
    width: "max-content",
  },
  "& .MuiTableCell-body": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600
  },
  "& .nameTxt": {
    color: "rgba(252, 252, 252, 1)",
    fontWeight: 700,
    fontFamily: 'Lato',
    height: "24px",
    width: "114px",
    fontSize: "15px"
  },
  "& .dobTableTxt": {
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    height: "81px",
    width: "24px"
  },
  "& .countryTableText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    width: "84px",
    height: "24px",
    fontFamily: 'Lato',
    whiteSpace: "nowrap"
  },
  "& .passportTextTable": {
    width: "81px",
    height: "24px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontFamily: 'Lato',
    fontWeight: 600,
  },
  "& .nationalityText": {
    width: "80px",
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    height: "24px"
  },
  "& .addressText": {
    color: "rgba(111, 118, 126, 1)",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato',
    width: "249px",
    height: "48px"
  },
  "& .nameTableCell": {
    width: "36px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .lato": {
    fontFamily: 'Lato'
  },
  "& .dobTableCell": {
    width: "78px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600,
    whiteSpace: "nowrap"

  },
  "& .nationalityTableCell": {
    color: 'rgba(211, 211, 211, 1)',
    width: "68px",
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .addressTableCell": {
    width: "50px",
    color: 'rgba(211, 211, 211, 1)',
    fontFamily: 'Lato',
    height: '16px',
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .passportTableCell": {
    width: "106px",
    fontFamily: 'Lato',
    fontWeight: 600,
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .documentTableCell": {
    gap: '0px',
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .statusTableCell": {
    width: "70px",
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    fontWeight: 600,
    height: '16px',
    gap: '0px',
    opacity: "0px",
  },
  "& .countryTableCell": {
    fontFamily: 'Lato',
    color: 'rgba(211, 211, 211, 1)',
    height: '16px',
    width: "50px",
    gap: '0px',
    opacity: "0px",
    fontWeight: 600
  },
  "& .documentNameText": {
    fontFamily: 'Lato',
    fontWeight: 600,
    color: "rgba(111, 118, 126, 1)"
  }
});

const UserName = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px"
});

const StatusWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  
  "& .rejectedBtn": {
    backgroundColor: 'red',
    color: 'rgba(36, 36, 36, 1)',
    width: "98px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .approvedBtn": {
    backgroundColor: '#41d613',
    color: 'rgba(36, 36, 36, 1)',
    width: "76px",
    height: "28px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  },
  "& .downloadBtn": {
    width: "16px",
    height: "16px"
  },
  "& .downloadLargeBtn": {
    width: "24px",
    height: "24px"
  },
  "& .pendingBtn": {
    backgroundColor: 'yellow',
    color: 'rgba(36, 36, 36, 1)',
    width: "80px",
    height: "28px",
    extTransform: "none",
    marginLeft: "33px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "24px"
  }, 
  "& .needClarificationBtn": {
    backgroundColor: "#FF7A01",
    color: "rgba(36, 36, 36, 1)",
    width: "200px",
    height: "36px",
    textTransform: "none",
    borderRadius: "0px",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px"
  },
 
})

const PagginationBox = styled(Box)({
  marginLeft: "auto",
  marginTop: "20px",
  marginBottom:"35px",
  width: "max-content",
  "& .MuiPaginationItem-page.Mui-selected": {
    border: "1px solid grey !important"
  },
  "& .MuiPagination-ul .MuiButtonBase-root::before": {
    content: "",
  },
  "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
      backgroundColor: "transparent !important",
  },

  "& .MuiPaginationItem-page.Mui-selected::before": {
    backgroundColor: "transparent !important",
  },
  "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
      backgroundColor: "transparent !important",
  },
  "& .showingTxt": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "14px",
      color: "#212121"
  },
  "& .MuiSvgIcon-root": {
      fill: "grey"
  },

  "& .MuiPaginationItem-page": {
      backgroundColor: "unset",
      color: "rgba(0, 255, 0, 1)",
      borderBottom: "unset !important",
  },
  "& .MuiPagination-ul .MuiButtonBase-root": {
      position: "relative",
      borderRadius: "50%",
      border: "none"
  },
  "@media (max-width: 768px)": {
      justifyContent: "center",
      margin: "20px auto",
  },
});
const PageTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "44px",
  paddingBottom: "20px",
  "& .searchInputfield": {
      backgroundColor: "#505050",
      color: "#fff",
      height: "44px",
      fontSize: "16px",
      fontWeight: 400,
      fontFamily: "Lato",
      width: "320px",
      outline: "none",
      border: "none",
      borderRadius: "0",
      padding: "10px",

      "& .MuiInputBase-input": {
          width: "272px",
          height: "24px",
          fontFamily: "Lato",
      },
  },

  "& .MuiOutlinedInput-inputAdornedStart": {
      color: "#A7A7A7",
      height: "7px",
  },
  "& .searchContainer": {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px",
    outline: "none",
    alignItems: "center",
    border: "none",
    borderRadius: "0px",
    
    "& .searchInputContainer": {
        position: "relative",
        
        "& .searchStatusContainer": {
            position: "absolute",
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            backgroundColor: "rgba(36, 36, 36, 1)",
            width: "100%",
            listStyle: "none",
            margin: 0,
            height: "10px",

            "& .searchStatus": {
                padding: "12px",
                fontSize: "8px",
                fontWeight: 600,
                lineHeight: "20px",
                fontFamily: "lato",
                textTransform: "capitalize",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },

            "& .searchApprovedStatus": {
                border: "1px solid rgba(0, 255, 0, 1)",
                color: "rgba(0, 255, 0, 1)",
            },
            "& .searchPendingStatus": {
                border: "1px solid rgba(251, 212, 0, 1)",
                color: "rgba(251, 212, 0, 1)",
            },
            "& .searchRejectedStatus": {
                border: "1px solid rgba(255, 25, 56, 1)",
                color: "rgba(255, 25, 56, 1)",
            },
            "& .searchClarificationStatus": {
                border: "1px solid rgba(255, 122, 1, 1)",
                color: "rgba(255, 122, 1, 1)",
            },
        },
    },
},

  "& .searchIcon": {
      color: "#A7A7A7",
      marginRight: "20px",
      width: "17.05px",
      height: "17.06px",
      cursor: "pointer",
  },

  "& .crossIcon": {
      color: "#A7A7A7",
      marginRight: "20px",
      width: "18.05px",
      height: "18.06px",
      cursor: "pointer",
  },

  "& .searchBtn": {
      height: "44px",
      width: "181px",
      backgroundColor: "#00FF00",
      color: "#242424",
      borderRadius: "0px",
      textTransform: "none",
  },

  "& .pageMain": {
      height: "44px",
      width: "1333px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
  },

  "@media (max-width: 1024px)": {
      "& .searchInputfield": {
          width: "250px",
      },
      "& .pageMain": {
          width: "100%",
          padding: "0 20px",
      },
      "& .searchBtn": {
          width: "150px",
      },
  },
  "@media (max-width: 768px)": {
      flexDirection: "column",
      "& .pageMain": {
          flexDirection: "column",
          alignItems: "flex-start",
      },
      "& .searchContainer": {
          flexDirection: "column",
          alignItems: "flex-start",
      },
      "& .searchInputfield": {
          width: "100%",
      },
      "& .searchBtn": {
          width: "100%",
      },
  },

  "@media (max-width: 480px)": {
      "& .searchInputfield": {
          height: "36px",
          fontSize: "14px",
      },
      "& .searchBtn": {
          width: "100%",
          fontSize: "14px",
      },
  },
});
// Customizable Area End
