Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.loginText = 'Log In/Sign Up';
exports.clickText = "Click, Check, Connect";
exports.servicesText = "Services";
exports.provideText = "We provide you";
exports.scaleKycText = "Scale KYC";
exports.firstText = 'Digital onboarding and online KYC checks are crucial for businesses to comply with regulations, mitigate risks and build trust with customers while providing a seamless user experience. Scale KYC is for your businesses to verify the identity of your customers. Through an easy interface, online KYC checks will be conducted by verifying customer identities through digital means.';
exports.digitalText = "Digital onboarding";
exports.secondText = ' Without the need for physical paperwork or in-person visits, customers can be onboarded using electronic methods to collect and verify customer information. Scale KYC makes the customer registration process faster, more convenient and more secure.';
exports.readMoreText = 'READ MORE';
exports.supportText = "KYC Support";
exports.thirdText = 'We are here for you. Scale KYC is complemented with human capital to interpret results, help you through the onboarding process or engage in compliance support';
exports.aboutText = 'About Us';
exports.guideText = 'We guide you';
exports.forthText = "Scale Compliance is a highly experienced crypto compliance company. Based in Switzerland and Dubai we are uniquely suited to build processes that are easy to understand and fully compliant with the regulatory compliance framework";
exports.fifthText = 'As your dedicated partner for tailored crypto compliance solutions, Scale Compliance offers a global KYC/AML platform ensuring swift and precise verifications. Engaging with Scale Compliance is a straightforward, rapid, and regulatory-compliant process. With expertise in the financial sector, we understand the intricacies and challenges of the crypto landscape. Originating from the Crypto Valley in Switzerland, we have expanded our services to the UAE, establishing Scale Compliance as one of the pioneering companies making us your trusted experts in this domain';
exports.contactText = 'Contact us';
exports.workText = "Let's work together";
exports.sixthText = "Let us help you become even greater at what you do. Fill out the following form and we will get back to you in the next 24 hours";
exports.submitText = 'SUBMIT';
exports.landingPageEndPoint="bx_block_contact_us/contacts/create_contactus";
exports.privacyPageEndPoint="bx_block_content_management/privacy_policies?role_name=company";
exports.emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.phoneNumber = "Phone Number";
// Customizable Area End